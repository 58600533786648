import React, {useEffect, useRef, useState} from "react";
import {
  Card,
  Table,
} from "react-bootstrap";
import {Col, Button, Spinner} from 'reactstrap';
import {useHistory} from "react-router";
import {useDispatch, useSelector} from 'react-redux';
import {getRoleLabels} from "../../constants/roles";
import Switch from '@mui/material/Switch';
import {API} from 'aws-amplify';
import {updateUser} from "../../graphql/mutations";
import {APP_AUTH_MODE, APP_TYPE, APP_TYPES} from "../../constants/app";
import {BUTTON_LABELS, MESSAGES, NOTIFICATION_MESSAGES} from "../../lang/ja";
import PaginationComponent from '../../components/PaginationComponent';
import {NUM_ITEMS_PER_PAGE} from "../../constants/table";
import { listUsersInGroups } from "../../apis/UserApi";
import { useDialog } from "../../contexts/DialogContext";
import { Auth } from 'aws-amplify';
import {deleteUser} from "../../features/user/userActions";

const Accounts = () => {
  const dispatch = useDispatch();
  const {loading} = useSelector(state => state.users);
  const history = useHistory();
  const governmentId = history.location.pathname.split('/')[3];
  const [currentPage, setCurrentPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const nextTokenRef = useRef(null);
  const { error } = useDialog();
  useEffect(() => {
    (async() => {
      await getUsers();
      setInitialLoad(false);
    })()
  }, []);

  const fetchUserData = async () => {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const getUsers = async (shouldAppend = false) => {
    if (!shouldAppend) {
      nextTokenRef.current = null;
      setCurrentPage(0);
    }
    let groups = [];
    if (APP_TYPE===APP_TYPES.OUTSIDE_ADMIN) {
      groups = ['outsideAdminStaff'];
    }
    else if (APP_TYPE===APP_TYPES.INSIDE_ADMIN) {
      groups = ['insideAdminStaff', 'staff'];
    }
    else if (APP_TYPE===APP_TYPES.ADMIN) {
      groups = ['outsideAdmin', 'outsideAdminStaff', 'insideAdmin', 'insideAdminStaff', 'staff'];
    }
    const res = await listUsersInGroups(governmentId, groups, nextTokenRef.current);
    if (res.success) {
      if (shouldAppend) {
        users.push(...res.data);
        setUsers(Object.assign([], users));
      }
      else {
        setUsers(res.data);
      }
      nextTokenRef.current = res.nextToken;
    }
    else {
      error(MESSAGES.SOMETHING_WRONG);
    }
  };

  const handleChangePage = async (newPage) => {
    setCurrentPage(newPage);
    if (newPage +1 === Math.ceil(users.length / NUM_ITEMS_PER_PAGE) && nextTokenRef.current) {
      await getUsers(true);
    }
  };

  const toggleUserStatus = async (user) => {
    try {
      const id = user.id;
      const enabled = user.enabled === null ? true : !user.enabled;
      const response = await API.post('userRestApi', '/toggle-user-status', {
        body: {
          userId: id,
          enabled
        }
      });
      if (response?.success) {
        const newUser = JSON.parse(response.data);
        users.splice(users.indexOf(user), 1, newUser);
        setUsers(Object.assign([], users));
      } else {
        console.error("Failed to toggle user status:", response?.message);
      }
    } catch (error) {
      console.error("Error calling the API:", error);
    }
  }

  const handleDeleteUser = async (id) => {
    const result = confirm("Are you sure to delete this user?");
    if (result === true) {
      try {
        const {data} = await API.graphql({
          query: updateUser,
          variables: {
            input: {
              id,
              isDeleted: true
            }
          },
          authMode: APP_AUTH_MODE
        });

        if (data?.updateUser) {
          console.log("data?.updateUser",data?.updateUser)
          const cognitoDeleteResult =  await dispatch(deleteUser(data?.updateUser?.username));
          if (cognitoDeleteResult.error) {
            console.error("Error when deleting user:", cognitoDeleteResult.payload);
            await API.graphql({
              query: updateUser,
              variables: {
                input: {
                  id,
                  isDeleted: false
                }
              },
              authMode: APP_AUTH_MODE
            });
          }
          await getUsers()
          fetchUserData(data?.updateUser?.username)
        } else {
          console.error("Error when deleting user:", data?.message);
        }
      } catch (error) {
        console.error("Error when deleting user:", error);
      }
    }
  }

  /*
    const handleSyncUsers = async () => {
        const jwtToken = localStorage.getItem('userToken');
        const options = {
            headers: {
                Authorization: jwtToken,
            },
        };

        const response = await API.get('userRestApi', '/sync-users', options);

    }
    */

  return <Col md="12">
    <Card className="strpied-tabled-with-hover">
      <Card.Header>
        <Card.Title as="h4">アカウントリスト</Card.Title>
        <div className="mb-2 d-flex flex-row justify-content-between align-items-center">
          <PaginationComponent
            currentPage={currentPage}
            totalItems={users.length}
            itemsPerPage={NUM_ITEMS_PER_PAGE}
            onPageChange={handleChangePage}
          />
          <Button
            className="btn-outline btn-wd mr-1"
            variant="default"
            onClick={() => history.push(`/admin/governments/${governmentId}/accounts/create`)}
          >
            {BUTTON_LABELS.BUTTON_REGISTER_NEW}
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="table-responsive p-0 overflow-auto">
        {loading ? <Spinner/> : <>
          {users.length || initialLoad ? <Table className="table-hover table-striped w-full">
            <thead>
              <tr>
                <th>ユーザID</th>
                <th>グループ</th>
                <th style={{textAlign: 'center'}}>有効</th>
                <th>アクション</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => {
                if (index < currentPage * NUM_ITEMS_PER_PAGE || index >= (currentPage + 1) * NUM_ITEMS_PER_PAGE) return null;
                return (
                  <tr key={index}>
                    <td>{user.username}</td>
                    <td>{user?.groups && user.groups.length > 0 ? getRoleLabels(user.groups) : 'No Group'}</td>
                    <td align="center">
                      <Switch onChange={() => toggleUserStatus(user)} checked={user.enabled || user.enabled === null}/>
                    </td>
                    <td>
                      <Button className="mr-3 btn-outline" onClick={() => history.push(`/admin/accounts/${user.id}/edit`)}>{BUTTON_LABELS.BUTTON_EDIT}</Button>
                      <Button className="mr-3 btn-outline" onClick={() => handleDeleteUser(user.id)}>{BUTTON_LABELS.BUTTON_DELETE}</Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table> : <p style={{textAlign: 'center'}}>{NOTIFICATION_MESSAGES.HAVE_NO_USER}</p>}
        </>}
      </Card.Body>
    </Card>
  </Col>
}

export default Accounts;
