import React from 'react';
import {BrowserRouter, Redirect, Switch, Route} from "react-router-dom";
import AdminLayout from "./layouts/Admin";
import Login from "./pages/login";
import PrivateRoute from "./routing/PrivateRoute";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Switch>
          <Route exact path='/auth/login' component={Login}/>
          <Route path='/admin' render={() => (
            <PrivateRoute>
              <AdminLayout/>
            </PrivateRoute>
          )}/>
          <Redirect from="/" to="/admin/qa_list"/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
