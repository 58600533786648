import PropTypes from "prop-types";
import React from "react";
import { Pagination } from "react-bootstrap";
import { NUM_ITEMS_PER_PAGE } from "../constants/table";

const propTypes = {
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
  nextPageIndex: PropTypes.string,
  onPageChange: PropTypes.func,
};

const defaultProps = {
  currentPage: 0,
  itemsPerPage: NUM_ITEMS_PER_PAGE,
  nextPageIndex: null,
  onPageChange: () => {},
};

const PaginationComponent = ({ currentPage, nextPageIndex, totalItems, itemsPerPage, onPageChange }) => {
  const numPages = Math.ceil(totalItems / itemsPerPage);

  const onClickPage = (page) => {
    if (page >= 0 && page < numPages) {
      onPageChange(page);
    }
  };

  const paginationItems = () => {
    const items = [];
    const numDisplayedPages = 10;

    // Calculate start and end page numbers
    let startPage = Math.max(0, currentPage - 4);
    let endPage = Math.min(numPages - 1, currentPage + 5);

    // Ensure we display numDisplayedPages items
    while (endPage - startPage < numDisplayedPages - 1 && (startPage > 0 || endPage < numPages - 1)) {
      if (startPage > 0) {
        startPage--;
      }
      if (endPage < numPages - 1) {
        endPage++;
      }
    }

    // Add ellipsis if necessary
    if (startPage > 0) {
      items.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    // Add page items
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onClickPage(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }

    // Add ellipsis if necessary
    if (endPage < numPages - 1 || nextPageIndex) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    return items;
  };

  return (
    <Pagination className="pagination pagination-no-border" style={{ margin: 0 }}>
      <Pagination.Item onClick={() => onClickPage(currentPage - 1)} disabled={currentPage <= 0}>
        «
      </Pagination.Item>
      {paginationItems()}
      <Pagination.Item onClick={() => onClickPage(currentPage + 1)} disabled={currentPage >= numPages - 1}>
        »
      </Pagination.Item>
    </Pagination>
  );
};

PaginationComponent.propTypes = propTypes;
PaginationComponent.defaultProps = defaultProps;

export default PaginationComponent;
