import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { BUTTON_LABELS, MESSAGES } from "../../lang/ja";

const WebRegisterDialog = ({
  isOpen,
  isNew,
  url: initialUrl,
  onClose,
  onRegister,
  editable,
}) => {
  const [url, setUrl] = useState(initialUrl);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setUrl("");
      setValidated(false);
    } else {
      setUrl(initialUrl);
    }
  }, [isOpen, initialUrl]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      onRegister(url);
      setUrl("");
      onClose();
    }
    setValidated(true);
  };

  if (!isOpen) return null;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdropClassName="modal show"
      centered
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editable
              ? !isNew
                ? BUTTON_LABELS.BUTTON_EDIT
                : BUTTON_LABELS.BUTTON_REGISTER_NEW
              : BUTTON_LABELS.BUTTON_DETAIL}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group as={Row} controlId="urlInput">
            <Form.Label column sm={3}>
              URL
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="url"
                placeholder="https://example.com"
                required
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                disabled={!editable}
              />
              <Form.Control.Feedback type="invalid">
                {MESSAGES.ENTER_URL}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <Button
            className="btn-outline btn-wd mr-1"
            variant="default"
            onClick={onClose}
          >
            {BUTTON_LABELS.BUTTON_CANCEL}
          </Button>
          {editable && (
            <Button
              className="btn-outline btn-wd mr-1"
              variant="default"
              type="submit"
            >
              {BUTTON_LABELS.BUTTON_REGISTER}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

WebRegisterDialog.propTypes = {
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  url: PropTypes.string,
  onClose: PropTypes.func,
  onRegister: PropTypes.func,
  editable: PropTypes.bool,
};

WebRegisterDialog.defaultProps = {
  isOpen: false,
  isNew: true,
  url: "",
  onClose: () => {},
  onRegister: () => {},
  editable: true,
};

export default WebRegisterDialog;
