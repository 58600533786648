import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import "./CSVDownloadDialog.css";

const propTypes={
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDownload: PropTypes.func
};
const defaultProps={
  isOpen: false,
  onClose: () => {},
  onDownload: ()=>{}
};
const CSVDownloadDialog = (props) => {
  /*** States and Variables ***/
  const { isOpen, onClose, onDownload } = props;
  const inputRef = React.useRef(null);
  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/
  if (!isOpen) return null;
  return <div
  className="modal show"
  style={{ display: 'block', position: 'absolute' }}>
    <Modal.Dialog className="p-0">
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>CSVダウンロード</Modal.Title>
      </Modal.Header>
      <Modal.Body
      style={{
        maxHeight: '256px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <input 
        ref={inputRef}
        type="month"
        className='csv_download_dialog_input'/>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button 
        className="btn-outline btn-wd mr-1"
        variant="default"
        onClick={onClose}>
          キャンセル
        </Button>
        <Button 
        className="btn-outline btn-wd mr-1"
        variant="default"
        onClick={()=>onDownload(inputRef.current.value)}>
          ダウンロード
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  </div>
}
CSVDownloadDialog.propTypes = propTypes;
CSVDownloadDialog.defaultProps = defaultProps;
export default CSVDownloadDialog;