import { APP_TYPE } from "./app";

export const ROLES = [
  { id: "insideAdmin", label: "内部向け管理者" },
  { id: "insideAdminStaff", label: "内部向けスタッフ" },
  { id: "outsideAdmin", label: "外部向け管理者" },
  { id: "outsideAdminStaff", label: "外部向けスタッフ" },
  { id: "senkyoAdmin", label: "Senkyo Admin" },
  { id: "staff", label: "職員ユーザー" },
];

export const APP_TYPE_TO_GROUPS = {
  INSIDE_ADMIN: ["insideAdmin", "insideAdminStaff"],
  OUTSIDE_ADMIN: ["outsideAdmin", "outsideAdminStaff"],
  ADMIN: ["senkyoAdmin"],
  ALL: ["senkyoAdmin", "outsideAdmin", "outsideAdminStaff", "insideAdmin", "insideAdminStaff"],
};

let rolesToInclude;

switch (APP_TYPE) {
  case "INSIDE_ADMIN":
    rolesToInclude = ["insideAdminStaff", "staff"];
    break;
  case "OUTSIDE_ADMIN":
    rolesToInclude = ["outsideAdminStaff"];
    break;
  default:
    rolesToInclude = ["staff", "insideAdmin", "insideAdminStaff", "outsideAdmin", "outsideAdminStaff"];
}

export const ADMIN_CREATE_ROLES = ROLES.filter((role) => rolesToInclude.includes(role.id));

export const getRoleLabels = (groups) => {
  const groupMap = new Map(ROLES.map((role) => [role.id, role.label]));
  return groups
    .map((groupId) => groupMap.get(groupId))
    .filter((label) => label !== undefined)
    .join(", ");
};
