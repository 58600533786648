import { createApi } from "@reduxjs/toolkit/query/react";
import { API, Auth } from "aws-amplify";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: async (request) => {
    try {
      const currentSession = await Auth.currentSession();

      const userToken = currentSession.getAccessToken().getJwtToken();

      if (!userToken) {
        localStorage.clear();
        throw new Error("Not logged in");
      }

      const response = await API.get(request.api, request.path, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      return { data: response.data };
    } catch (error) {
      return { error: error.message || "Failed to fetch data" };
    }
  },
  endpoints: (build) => ({
    getUserDetails: build.query({
      query: () => ({
        api: "userRestApi",
        path: "/get-user-info",
      }),
      pollingInterval: 15 * 60 * 1000,
    }),
  }),
});

export const { useGetUserDetailsQuery } = authApi;
