import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ChatboxSettingForm.css";
import { faChevronDown, faCircleXmark, faPaperPlane, faRobot } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { Button } from "react-bootstrap";
import { Storage } from "aws-amplify";
import { useSelector } from 'react-redux';
import awsConfig from '../../aws-exports';
import FontIcon from "../../assets/img/FontIcon";
import { useDialog } from "contexts/DialogContext";
import { MESSAGES } from "../../lang/ja";

const propTypes = {};
const defaultProps = {};

const INIT_CONFIG = {
  "initMsg": "",
  "headerColor": "#45A3B0",
  "headerTextColor": "#F00",
  "firstViewText": {
    "ja": "こんにちは！\nチャットを始める時は\n下のボタン押してね",
    "en": "Hello!\nWhen to start chatting\npress the button below",
  },
  "formColor": "#DCEEF0",
  "humanBalloonColor": "#45A3B0",
  "humanBalloonTextColor": "#FFFFFF",
  "botBalloonColor": "#FFFFFF",
  "botBalloonTextColor": "#4D4D4D",
  "balloonTextSize": "14px",
  "sendIconColor": "#158c9c",
  "evaluationBackgroundColor": "#FFFFFF",
  "evalutationTitleColor": "#45A3B0",
  "evaluationSelectedButtonColor": "#FFF",
  "evaluationSelectedButtonBackgroundColor": "#45A3B0"
};
const ITEM_GROUP0 = [
  {key: 'headerColor', name: 'ヘッダー色', type: 'color', default: '#45A3B0'},
  {key: 'headerTextColor', name: 'ヘッダーテキスト色', type: 'color', default: '#F00'},
];
const ITEM_GROUP1 = [
  {key: 'formColor', name: '背景色', type: 'color', default: '#DCEEF0'},
  {kry: 'icon', name: 'アイコンファイル', type: 'file'},
  {key: 'humanBalloonColor', name: 'ユーザチャットボックス色', type: 'color', default: '#45A3B0'},
  {key: 'humanBalloonTextColor', name: 'ユーザチャットテキスト色', type: 'color', default: '#FFFFFF'},
  {key: 'botBalloonColor', name: 'ボットチャットボックス色', type: 'color', default: '#FFFFFF'},
  {key: 'botBalloonTextColor', name: 'ボットチャットテキスト色', type: 'color', default: '#4D4D4D'},
  {key: 'balloonTextSize', name: 'チャットテキストサイズ', type: 'number', default: '14'},
  {key: 'balloonTextBigSize', name: 'チャットテキストサイズ(大)', type: 'number', default: '16'},
  {key: 'sendIconColor', name: '送信アイコン色', type: 'color', default: '#158c9c'}
];
const ITEM_GROUP2 = [
  {key: 'evaluationBackgroundColor', name: '評価背景色', type: 'color', default: '#FFFFFF'},
  {key: 'evalutationTitleColor', name: '評価タイトル色', type: 'color', default: '#45A3B0'},
  {key: 'evaluationSelectedButtonColor', name: '選択ボタンのテキスト色', type: 'color', default: '#FFFFFF'},
  {key: 'evaluationSelectedButtonBackgroundColor', name: '選択ボタンの背景色', type: 'color', default: '#45A3B0'}
];
const FAKE_MESSAGES = [
  {
    id: '0',
    role: 'USER',
    text: 'これは質問です。'
  },
  {
    id: '1',
    role: 'BOT',
    text: 'これは答えです。\nhttps://www.xxxxxx.co.jp'
  }
];
const EVALUATION_TEXTS_1 = ['良い', '悪い'];
const EVALUATION_TEXTS_2 = ['質問の意図を汲み取れていない', '日本語として変', '解答の意味が分からなかった'];

const LANGUAGES = ['ja', 'en', 'zh', 'zh-cn', 'ko', 'vi', 'th'];
const ChatboxSettingForm = () => {
  /***** States and Variables *****/
  const { register, handleSubmit, watch, setValue } = useForm();
  const config = watch(undefined, INIT_CONFIG);
  const { userInfo } = useSelector((state) => state.users)
  const cityId = userInfo?.governmentId;
  const [svgFile, setSvgFile] = React.useState(null);
  const [size, setSize] = React.useState(14);
  const [lang, setLang] = React.useState('ja');
  const { success, error } = useDialog();
  /***** Processing *****/
  const downloadConfig = async () => {
    try {
      const res = await Storage.get(cityId + '.json', {download: true, cacheControl: 'no-cache'});
      let result = await res.Body.text();
      result = JSON.parse(result);
      const imgUrl = await Storage.get(cityId + '.svg', {cacheControl: 'no-cache'});
      setSvgFile(imgUrl);
      for(const key of Object.keys(result)) {
        setValue(key, result[key]);
      }
    }
    catch (e) {
      console.log("ERROR", e);
    }
  }
  React.useEffect(()=>{
    if (cityId) downloadConfig();
  }, [cityId])
  React.useEffect(()=>{
    setSize(config.balloonTextSize);
  }, [config.balloonTextSize])
  React.useEffect(()=>{
    setSize(config.balloonTextBigSize);
  }, [config.balloonTextBigSize])
  const urlify = (text)  => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex)
      .map(part => {
        if(part.match(urlRegex)) {
          // eslint-disable-next-line react/jsx-key
          return <a target='_blank' href={part} rel="noreferrer">{part}</a>;
        }
        return part;
      });
  }
  /***** Sub Components *****/
  const renderText = (text, url, isBot) => {
    return <Fragment>
      {text.split('\n').map((subText, i)=>{
        subText = subText.trim();
        return <label
          key={'lb_' + i + subText}
          style={{
            fontSize: size + 'px',
            color: isBot? config.botBalloonTextColor: config.humanBalloonTextColor
          }}>
          {urlify(subText)}
        </label>
      }
      )
      }
      {url && <a target='_blank'
        href={url} rel="noreferrer">
        {url}
      </a>}
    </Fragment>
  }
  const renderHeader = () => {
    return <div
      className='chatbox_big_container_header'
      style={{backgroundColor: config?.headerColor}}>
      <div className='chatbox_big_left_header'>
        <FontAwesomeIcon
          icon={faCircleXmark}
          style={{color: config?.headerTextColor}}/>
      </div>
      <div className='chatbox_big_right_header'>
        <button
          type="button"
          className='chatbox_select_header_button'
          style={{color: config?.headerTextColor}}>
          <FontIcon /> &nbsp;
          <FontAwesomeIcon icon={faChevronDown}/>
        </button>
        <button
          type="button"
          className='chatbox_select_header_button'
          style={{color: config?.headerTextColor}}>
          Language &nbsp;
          <FontAwesomeIcon icon={faChevronDown}/>
        </button>
      </div>
    </div>
  }
  const renderMessageList = () => {
    return <div
      style={{borderBottomColor: config.formColor}}
      className='chatbox_message_list_container'>
      <div className='chatbox_message_list_subcontainer'>
        {config.initMsg && <div className='chatbox_message_left_container'>
          <div className='chatbox_bot_icon'>
            {svgFile?
              <img
                className='chatbox_bot_image'
                src={svgFile}/>:
              <FontAwesomeIcon icon={faRobot}/>}
          </div>
          <div
            style={{backgroundColor: config.botBalloonColor}}
            className='chatbox_message_text_left_container'>
            {renderText(config.initMsg, null, true)}
          </div>
        </div>}
        {FAKE_MESSAGES.map(message=>{
          const isBotMsg = message.role==='BOT';
          return <div
            key={message.id}
            className={isBotMsg? 'chatbox_message_left_container': 'chatbox_message_right_container'}>
            {isBotMsg &&
            <div className='chatbox_bot_icon'>
              {svgFile?
                <img
                  className='chatbox_bot_image'
                  src={svgFile}/>:
                <FontAwesomeIcon icon={faRobot}/>}
            </div>}
            <div
              style={isBotMsg?{backgroundColor: config.botBalloonColor}: {backgroundColor: config.humanBalloonColor}}
              className={isBotMsg? 'chatbox_message_text_left_container': 'chatbox_message_text_right_container'}>
              {renderText(message.text, message.url, isBotMsg)}
              {isBotMsg &&
              <div className='chatbox_message_actions_container'>
                <button
                  type="button"
                  className='chatbox_message_action'>
                  <FontAwesomeIcon icon={faThumbsUp}/>
                </button>
                <button
                  type="button"
                  className='chatbox_message_action'>
                  <FontAwesomeIcon icon={faThumbsDown}/>
                </button>
              </div>}
            </div>
          </div>
        })}
      </div>
    </div>
  }
  const renderChatAreaText = () => {
    return <div className='chatbox_message_input_container'>
      <input
        className='chatbox_message_input'
        placeholder='質問を入力してください'/>
      <button
        type="button"
        style={{color: config.sendIconColor}}
        className='chatbox_button_send'>
        <FontAwesomeIcon icon={faPaperPlane}/>
      </button>
    </div>
  }
  const renderEvalutaion = () => {
    return <div className='chatbox_eval_container'>
      <div
        className='chatbox_eval_subcontainer'
        style={{
          backgroundColor: config.evaluationBackgroundColor
        }}>
        <label
          className='chatbox_eval_title'
          style={{
            color: config.evalutationTitleColor
          }}>
          このチャットの評価をしてください
        </label>
        <div className='chatbox_eval_buttons_container'>
          {EVALUATION_TEXTS_1.map((text)=>{
            const isSelected = text===EVALUATION_TEXTS_1[0];
            return <button
              key={text}
              type="button"
              className='chatbox_eval_button'
              style={{
                width: 'fit-content',
                backgroundColor: isSelected? config.evaluationSelectedButtonBackgroundColor: undefined,
                border: isSelected? ('solid 1px ' + config.evaluationSelectedButtonBackgroundColor): undefined,
                color: isSelected? config.evaluationSelectedButtonColor: undefined
              }}>
              {text}
            </button>})
          }
        </div>
        <label className='chatbox_eval_desc_text'>
          今後の改善のために問題点があれば下記の項目から選んで教えてください。
        </label>
        {
          EVALUATION_TEXTS_2.map((text)=>{
            const isSelected = text===EVALUATION_TEXTS_2[0];
            return <button
              key={text}
              type="button"
              className='chatbox_eval_button'
              style={{
                backgroundColor: isSelected? config.evaluationSelectedButtonBackgroundColor: undefined,
                border: isSelected? ('solid 1px ' + config.evaluationSelectedButtonBackgroundColor): undefined,
                color: isSelected? config.evaluationSelectedButtonColor: undefined
              }}>
              {text}
            </button>
          })
        }
        <button
          type="button"
          className='chatbox_eval_action_button'
          style={{
            color: config.evaluationSelectedButtonBackgroundColor
          }}>
          チャットに戻る
        </button>
        <button
          type="button"
          className='chatbox_eval_action_button'
          style={{
            color: config.evaluationSelectedButtonBackgroundColor
          }}>
          チャットを終了する
        </button>
      </div>
    </div>
  }
  const renderCode = () => {
    return <div className="chatbox_code_container">
      <label className="chatbox_setting_blue_code">
        &lt;script&nbsp;
      </label>
      <label className="chatbox_setting_purple_code">
        src
      </label>
      <label className="chatbox_setting_green_code">
        {awsConfig.aws_user_files_s3_bucket.endsWith('production')?
          `="http://storage.govai.jp/bundle.js"`:
          `="https://${awsConfig.aws_user_files_s3_bucket}.s3.${awsConfig.aws_user_files_s3_bucket_region}.amazonaws.com/public/bundle.js"`}
      </label>
      <label className="chatbox_setting_blue_code">
        &gt;
      </label><br/>
      <label className="chatbox_setting_blue_code">
        &lt;/script&gt;
      </label><br/>
      <label className="chatbox_setting_blue_code">
        &lt;script&gt;
      </label><br/>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <label className="chatbox_setting_blue_code">
      const&nbsp;
      </label>
      cityId=
      <label className="chatbox_setting_green_code">
       &quot;{cityId}&quot;
      </label>
      ;
      <br/>
      &nbsp;&nbsp;&nbsp;&nbsp;addChatbot(cityId);<br/>
      <label className="chatbox_setting_blue_code">
        &lt;/script&gt;
      </label>
    </div>
  }

  const renderMetaCode = () => {
    return <div className="chatbox_code_container">
      <pre className="chatbox_setting_blue_code">
        {`<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />`}
      </pre>
    </div>
  }

  /***** Event Handlers *****/
  const onUpdateSize = (value) => {
    setSize(parseInt(value))
  }
  const onSubmit = async (data) => {
    try {
      if (svgFile) {
        if (svgFile.startsWith('blob:')) {
          const temp = await fetch(svgFile);
          const svg = await temp.blob();
          await Storage.put(cityId + '.svg', svg, {contentType: 'image/svg+xml'});
        }
      } else {
        error(MESSAGES.MISSING_ICON);
        return;
      }
      data.icon = cityId + '.svg';
      await Storage.put(cityId + '.json', data);
      success(MESSAGES.SAVED_TAG_SETTING_SUCCESSFULLY);
    }
    catch (e) {
      console.log("ERROR", e)
      error(MESSAGES.SOMETHING_WRONG);
    }
  }
  /***** Main Render *****/
  return <form
    onSubmit={handleSubmit(onSubmit)}
    className="chatbox_setting_form_container">
    <div className="chatbox_setting_form_subcontainer">
      <label className="chatbox_setting_group_title">
        使い方
      </label>
      <div>以下のコードをbodyタグの一番下に設置してください。</div>
      {renderCode()}
      <div>スマートフォンでの最適化のために、以下コードをheadタグ内に設置してください。</div>
      {renderMetaCode()}

      <label className="chatbox_setting_group_title">
        初期画面
      </label>
      {LANGUAGES.map(lang=>{
        return <div
          key={lang}
          className="chatbox_setting_item_container">
          <label className="chatbox_setting_item_key">
            {`初期画面テキスト(${lang})`}
          </label>
          <textarea
            rows={2}
            className="chatbox_setting_init_msg_input"
            onClick={()=>{setLang(lang)}}
            {...register(`firstViewText.${lang}`)} />
        </div>})}
      <div className="chatbox_setting_form_block">
        <div className="chatbox_setting_items_container">
          {ITEM_GROUP0.map((item)=>{
            return <div
              key={item.key}
              className="chatbox_setting_item_container">
              <label className="chatbox_setting_item_key">
                {item.name}
              </label>
              <input
                className="chatbox_setting_item_value_input"
                defaultValue={item.default}
                type={item.type}
                {...register(item.key)}/>
            </div>
          })}
        </div>
        <div
          style={{backgroundColor: config.formColor}}
          className='chatbox_big_browser_container'>
          {renderHeader()}
          <div className="chatbox_first_view_content_container">
            <div
              className='chatbox_bot_icon'
              style={{
                width: '88px',
                height: '88px',
                marginBottom: '16px'
              }}>
              {svgFile?
                <img
                  className='chatbox_bot_image'
                  src={svgFile}/>:
                <FontAwesomeIcon icon={faRobot}/>}
            </div>
            {config?.firstViewText && config.firstViewText[lang] && config.firstViewText[lang].split('\n').map((t, i)=>{
              return <label
                key={t + i}
                className="chatbox_first_view_text"
                style={{

                }}>
                {t}
              </label>
            })}
            <label className="chatbox_first_view_small_text">
              個人情報などを入力はお控えください
            </label>
            <button
              type="button"
              className='chatbox_first_view_button'
              style={{
                backgroundColor: config?.headerColor,
                color: config?.headerTextColor
              }}>
              質問を開始する
            </button>
          </div>
        </div>
      </div>

      <label className="chatbox_setting_group_title">
        チャットボックスUI
      </label>
      <div className="chatbox_setting_item_container">
        <label className="chatbox_setting_item_key">
          紹介メッセージ
        </label>
        <textarea
          rows={3}
          className="chatbox_setting_init_msg_input"
          {...register('initMsg')} />
      </div>

      <div className="chatbox_setting_form_block">
        <div className="chatbox_setting_items_container">
          {ITEM_GROUP1.map((item)=>{
            if (item.type==='file') {
              return <div
                key={item.key}
                className="chatbox_setting_item_container">
                <label className="chatbox_setting_item_key">
                  {item.name}
                </label>
                <div className="chatbox_setting_file_container">
                  <img
                    className='chatbox_setting_image'
                    src={svgFile}
                    onError={()=>{setSvgFile('')}}/>
                  <input type='file'
                    className="chatbox_setting_input"
                    accept=".svg"
                    onChange={(e)=>{setSvgFile(URL.createObjectURL(e.target.files[0]))}}/>
                </div>
              </div>
            }
            return <div
              key={item.key}
              className="chatbox_setting_item_container">
              <label className="chatbox_setting_item_key">
                {item.name}
              </label>
              <input
                className="chatbox_setting_item_value_input"
                defaultValue={item.default}
                type={item.type}
                onClick={(e)=>{item.key==='balloonTextSize' || item.key==='balloonTextBigSize'? onUpdateSize(e.target.value): null}}
                {...register(item.key)}/>
            </div>
          })}
        </div>
        <div
          style={{backgroundColor: config.formColor}}
          className='chatbox_big_browser_container'>
          {renderHeader()}
          {renderMessageList()}
          {renderChatAreaText()}
        </div>
      </div>

      <label className="chatbox_setting_group_title">
        チャット評価UI
      </label>
      <div className="chatbox_setting_form_block">
        <div className="chatbox_setting_items_container">
          {ITEM_GROUP2.map((item)=>{
            return <div
              key={item.key}
              className="chatbox_setting_item_container">
              <label className="chatbox_setting_item_key">
                {item.name}
              </label>
              <input
                className="chatbox_setting_item_value_input"
                defaultValue={item.default}
                type={item.type}
                {...register(item.key)}/>
            </div>
          })}
        </div>
        {renderEvalutaion()}
      </div>
      <div className="d-flex-row">
        <Button
          className="btn-outline btn-wd mr-1 chatbox_setting_button_save"
          variant="default"
          type="submit">
          保存
        </Button>
        <Button
          className="btn-outline btn-wd mr-1 chatbox_setting_button_save"
          variant="default"
          type="button"
          onClick={downloadConfig}>
          リセット
        </Button>
      </div>
    </div>
  </form>;
}

ChatboxSettingForm.propTypes = propTypes;
ChatboxSettingForm.defaultProps = defaultProps;

export default ChatboxSettingForm;
