import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, Auth } from "aws-amplify";
import { APP_AUTH_MODE, APP_TYPE } from "../../constants/app";
import { APP_TYPE_TO_GROUPS } from "../../constants/roles";
import { createUser } from "../../graphql/mutations";
import { findUsersByGovernmentId, getGovernment, getUser } from "../../graphql/queries";
import { VALIDATION_MESSAGES } from "../../lang/ja";
import { amplifyErrorHandler } from "../../utils/errorHandler";

export const userLogin = createAsyncThunk(
  "users/login",
  async ({ username, password, newPassword, ipAddress }, { rejectWithValue }) => {
    try {
      let user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        if (newPassword) {
          user = await Auth.completeNewPassword(user, newPassword);
        } else {
          return rejectWithValue("NEW_PASSWORD_REQUIRED");
        }
      }

      const {
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = user;

      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const userGroups = currentUser.signInUserSession.accessToken.payload["cognito:groups"];

      const currentAppGroups = APP_TYPE_TO_GROUPS[APP_TYPE];

      if (!userGroups.some((group) => currentAppGroups.includes(group))) {
        await Auth.signOut();
        return rejectWithValue(VALIDATION_MESSAGES.USER_DOES_NOT_BELONG);
      }

      const {
        data: {
          getUser: { governmentId, id },
        },
      } = await API.graphql({
        query: getUser,
        variables: { id: currentUser.attributes.sub },
        authMode: APP_AUTH_MODE,
      });

      if (!id) {
        await Auth.signOut();
        return rejectWithValue(VALIDATION_MESSAGES.USER_NOT_EXIST);
      }

      const {
        data: {
          getGovernment: { ipWhitelist },
        },
      } = await API.graphql({
        query: getGovernment,
        variables: { governmentId },
      });

      if (ipWhitelist && ipWhitelist.length && !ipWhitelist.includes(ipAddress)) {
        await Auth.signOut();
        return rejectWithValue(VALIDATION_MESSAGES.IP_NOT_ACCEPTED);
      }

      localStorage.setItem("userToken", jwtToken);

      return {
        username: user.username,
        userToken: jwtToken,
        groups: userGroups,
        governmentId,
      };
    } catch (error) {
      return rejectWithValue(amplifyErrorHandler(error));
    }
  }
);

export const fetchUsers = createAsyncThunk("users/fetch", async ({ governmentId }, { rejectWithValue }) => {
  try {
    const variables = {
      governmentId,
      filter: {
        isDeleted: {
          ne: true,
        },
        username: {
          attributeExists: true,
        },
        email: {
          attributeExists: true,
        },
      },
    };

    const { data } = await API.graphql({
      query: findUsersByGovernmentId,
      variables,
      authMode: APP_AUTH_MODE,
    });

    return data?.findUsersByGovernmentId ? data.findUsersByGovernmentId.items : [];
  } catch (error) {
    return rejectWithValue(error?.message ?? "Error fetching users");
  }
});

export const deleteUser = createAsyncThunk("users/deleteUser", async (username, { rejectWithValue }) => {
  try {
    const jwtToken = localStorage.getItem("userToken");
    const options = {
      headers: {
        Authorization: jwtToken,
      },
      body: { username },
    };

    const response = await API.post("userRestApi", "/delete-user", options);

    if (response && response.success) {
      return response.message;
    } else {
      return rejectWithValue(response?.error ?? "Unknown error deleting user");
    }
  } catch (error) {
    return rejectWithValue(error?.message ?? "Error deleting user");
  }
});

export const registerUser = createAsyncThunk(
  "users/register",
  async ({ username, email, password, governmentId, roles, enabled }, { rejectWithValue }) => {
    try {
      const cognitoResult = await API.post("userRestApi", "/create-user", {
        body: {
          username: username,
          email: email,
          password: password,
          governmentId: governmentId,
          roles,
        },
      });
      if (cognitoResult.user) {
        const user = {
          id: cognitoResult.user.userId,
          email,
          governmentId,
          createdAt: new Date().toISOString(),
          messageCount: 0,
          groups: roles,
          username,
          enabled,
        };
        try {
          await API.graphql({ query: createUser, variables: { input: user }, authMode: APP_AUTH_MODE });
        } catch (error) {
          return rejectWithValue(amplifyErrorHandler(error.errors ? error.errors[0] : error));
        }
      }
      return cognitoResult;
    } catch (error) {
      return rejectWithValue(amplifyErrorHandler(error));
    }
  }
);

export const logoutUser = createAsyncThunk("users/logout", async (_, { rejectWithValue }) => {
  try {
    localStorage.clear();
    await Auth.signOut();

    return true;
  } catch (error) {
    return rejectWithValue(error?.message ?? "Error during logout");
  }
});
