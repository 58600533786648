import { API } from 'aws-amplify';
import { findUsersByGovernmentId } from "../graphql/queries";
import { APP_AUTH_MODE } from '../constants/app';
import { NUM_ITEMS_PER_PAGE } from '../constants/table';

const listUsersInGroups = async (governmentId, groups=[], nextToken=null, minItems = 2*NUM_ITEMS_PER_PAGE) => {
  try {
    const items = [];
    const variables = {
      governmentId: governmentId,
      filter: {
        and: {
          isDeleted: {ne: true},
          email: {attributeExists: true},
          username: {attributeExists: true}
        }
      }
    };
    if (groups.length) {
      variables.filter.and.or = [];
      for (const group of groups) {
        variables.filter.and.or.push({
          groups: {contains: group}
        })
      }
    }
    // eslint-disable-next-line no-constant-condition
    while (true) {
      variables.nextToken = nextToken;
      const data = await API.graphql({
        query: findUsersByGovernmentId,
        variables,
        authMode: APP_AUTH_MODE
      });
      nextToken = data.data.findUsersByGovernmentId.nextToken;
      items.push(...data.data.findUsersByGovernmentId.items);
      if (!nextToken) break;
      if (items.length>=minItems) break;
    }
    return {
      success: true,
      data: items,
      nextToken
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

export {
  listUsersInGroups
}
