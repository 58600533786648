import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from "react-bootstrap-sweetalert";
import { APP_TYPE, APP_TYPES } from '../../constants/app';

const propTypes={
  resource: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};
const defaultProps={
  resource: null,
  title: "一括削除",
  message: null,
  onConfirm: () => {},
  onClose: ()=>{}
};

const DeleteDialog = (props) => {
  /*** States and Variables ***/
  const { resource, title, message, onClose, onConfirm } = props;
  /*** Processing ***/
  React.useEffect(()=>{
    // do something
  }, [resource])
  /*** Sub Components ***/
  /*** Event Handlers ***/
  
  /*** Main Render ***/
  // senkyoadmin only feature
  if (APP_TYPE!==APP_TYPES.ADMIN) return null;
  if (resource) {
    return <SweetAlert
            showCancel
            cancelBtnText="キャンセル"
            cancelBtnBsStyle="outline default"
            confirmBtnText="削除"
            confirmBtnBsStyle="danger"
            title={title}
            onConfirm={onConfirm}
            onCancel={onClose}
          >
            {message}
          </SweetAlert>
  }
  return null;
}
DeleteDialog.propTypes = propTypes;
DeleteDialog.defaultProps = defaultProps;
export default DeleteDialog;