import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircleCheck, faExclamationCircle, faClipboardCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import "./DialogContext.css";

const Context = createContext({});

// eslint-disable-next-line react/prop-types
export default function DialogProvider({ children }) {
  /***** States and Variables *****/
  const [message, setMessage] = useState(null);
  const hideToastRef = useRef();
  /***** Processing *****/
  useEffect(()=>{
    if (message) {
      clearTimeout(hideToastRef.current);
      if (message.type==='success' || message.type==='error'){
        hideToastRef.current = setTimeout(()=>{
          setMessage(null);
        }, 3000);
      }
    }
  }, [message])
  const success = (messageContent) => {
    setMessage({
      type: 'success',
      content: messageContent
    })
  }
  const error = (messageContent) => {
    setMessage({
      type: 'error',
      content: messageContent
    })
  }
  const confirm = (messageContent, onConfirm) => {
    setMessage({
      type: 'confirm',
      content: messageContent,
      onConfirm
    })
  }
  const warningConfirm = (messageContent, onConfirm) => {
    setMessage({
      type: 'warning-confirm',
      content: messageContent,
      onConfirm
    })
  }
  /***** Sub Components *****/
  const renderActions = () => {
    if (message?.type!=='warning-confirm' && message?.type!=='confirm') return null;
    return <div className='dialog_context_actions_container'>
      <button
        className='dialog_context_action'
        onClick={()=>{setMessage(null)}}>
        いいえ
      </button>
      <button
        className='dialog_context_action'
        onClick={onButtonConfirmClick}>
        はい
      </button>
    </div>
  }
  /***** Event Handlers *****/
  const onButtonConfirmClick = () => {
    if (message?.onConfirm) {
      message.onConfirm();
    }
    setMessage(null);
  }
  /***** Main Render *****/
  const isOpen = !!message;
  const darkColor = isOpen?
    ((message.type==='success' && '#35b37e') ||
  (message.type==='error' && '#ff5631') ||
  (message.type==='confirm' && '#3a88c8') ||
  '#ed991c'):
    'transparent';
  const lightColor = isOpen?
    ((message.type==='success' && '#e3fcef') ||
  (message.type==='error' && '#ffebe6') ||
  (message.type==='confirm' && '#a0d4ff') ||
  '#ffd89e'): 'transparent';
  const icon = isOpen?
    ((message.type ==='success'&& faCircleCheck) ||
  (message.type ==='error'&& faExclamationCircle) ||
  (message.type ==='confirm'&& faClipboardCheck) ||
  faExclamationTriangle): null;
  return (
    <Context.Provider value={{ success, error, confirm, warningConfirm }}>
      {children}
      <div
        style={{
          backgroundColor: lightColor,
          color: darkColor
        }}
        className={isOpen? 'dialog_context_container': 'dialog_context_hidden_container'}>
        <div
          style={{
            backgroundColor: darkColor
          }}
          className='dialog_context_left_margin'>
          {isOpen?<FontAwesomeIcon icon={icon} />: null}
        </div>
        <div className='dialog_context_right_side'>
          <label className='dialog_context_text'>
            {message?.content}
          </label>
          {renderActions()}
        </div>
        <button
          className='dialog_context_button_close'
          onClick={()=>{setMessage(null)}}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </Context.Provider>
  );
}

export const useDialog = () => useContext(Context);
