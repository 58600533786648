import { API } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import AccountForm from "../../components/AccountForm";
import SpinnerPage from "../../components/SpinnerPage";
import { APP_AUTH_MODE } from "../../constants/app";
import { getUser } from "../../graphql/queries";
import { editAccountSchema } from "../../utils/validationSchemas";

const EditAccount = () => {
  const { accountId } = useParams();
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const fetchAccountDetails = useCallback(async () => {
    setLoading(true);
    try {
      const result = await API.graphql({
        query: getUser,
        variables: { id: accountId },
        authMode: APP_AUTH_MODE,
      });
      const userData = result?.data?.getUser;
      if (userData) {
        const formattedData = {
          username: userData.username,
          email: userData.email,
          role: userData ? userData.groups[0] : null,
          status: userData.status,
        };
        setAccount(formattedData);
      }
    } catch (error) {
      console.error("Error fetching account details:", error);
    } finally {
      setLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    fetchAccountDetails();
  }, [fetchAccountDetails]);

  const handleEditAccount = useCallback(
    async (data) => {
      console.log(data);
      const jwtToken = localStorage.getItem("userToken");

      const options = {
        headers: {
          Authorization: jwtToken,
        },
        body: {
          userId: accountId,
          newPassword: data.password ?? null,
          newRole: data.role,
          username: data.username,
        },
      };
      try {
        const response = await API.post("userRestApi", "/update-user", options);
        const result = await API.graphql({
          query: getUser,
          variables: { id: accountId },
          authMode: APP_AUTH_MODE,
        });
        const userGov = result?.data?.getUser?.governmentId;
        if (response.success) {
          history.push(`/admin/governments/${userGov}/accounts`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [accountId, history]
  );

  return (
    <Col md="12">
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">アカウント編集</Card.Title>
        </Card.Header>
        <Card.Body className="table-responsive p-4">
          {loading ? (
            <SpinnerPage />
          ) : (
            <AccountForm
              onSubmit={handleEditAccount}
              defaultValues={account}
              loading={loading}
              isEdit={true}
              resolver={editAccountSchema}
              error={null} // Replace with proper error handling
            />
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default EditAccount;
