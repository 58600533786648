import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from 'react-bootstrap';
import "./FileRegisterDialog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRegister: PropTypes.func,
  accept: PropTypes.arrayOf(PropTypes.string),
  hasTemplate: PropTypes.bool,
  onDownloadTemplate: PropTypes.func
};
const defaultProps = {
  isOpen: false,
  onClose: ()=>{},
  onRegister: ()=>{},
  accept: [".docx", ".txt", ".pdf", ".csv", ".xlsx"],
  hasTemplate: false,
  onDownloadTemplate: ()=>{}
};
const FileRegisterDialog = (props) => {
  /***** Variables And States *****/
  const {
    isOpen,
    onClose,
    onRegister,
    hasTemplate,
    onDownloadTemplate
  } = props;
  const inputRef = React.useRef(null);
  const [dragging, setDragging] = React.useState(false);
  /***** Event Handler *****/
  const onDragEnter = () => {
    setDragging(true);
  }
  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }
  const onDrop = (e) => {
    setDragging(false);
    e.preventDefault();
    e.stopPropagation();
    const {files} = e.dataTransfer;
    const dataTransfer = new DataTransfer();
    for(const f of files) {
      if (!f.type) continue;
      const extension = f.name.substr(f.name.indexOf('.'));
      if (props.accept.includes(extension)) {
        dataTransfer.items.add(f);
      }
    }
    inputRef.current.files = dataTransfer.files;
  }
  /***** Main Render *****/
  if (!isOpen) return null;
  return <div
    className="modal show"
    style={{ display: 'block', position: 'absolute' }}
  >
    <Modal.Dialog className="p-0">
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title className="">CSVアップロード</Modal.Title>
      </Modal.Header>

      <Modal.Body
        className="d-flex flex-column"
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragEnd={()=>{setDragging(false)}}
        onDragExit={()=>{setDragging(false)}}
        onDrop={onDrop}
        style={{
          height: '196px',
          overflow: 'auto'
        }}>
        <Form.Control 
          ref={inputRef}
          type="file"
          accept={props.accept.join(', ')}
          multiple />
        {hasTemplate && <Button 
          className="btn-outline btn-wd mr-1 file_register_dialog_template"
          variant="default"
          onClick={onDownloadTemplate}>
        CSVテンプレート
        </Button>}
        {dragging && 
      <FontAwesomeIcon 
        icon={faCloudArrowUp}
        className="file_register_dialog_upload_icon"/>
        }
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button 
          className="btn-outline btn-wd mr-1"
          variant="default"
          onClick={onClose}>
        キャンセル
        </Button>
        <Button 
          className="btn-outline btn-wd mr-1"
          variant="default"
          onClick={()=>{onRegister(inputRef.current.files)}}>
        登録
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  </div>
}

FileRegisterDialog.propTypes = propTypes;
FileRegisterDialog.defaultProps = defaultProps;
export default FileRegisterDialog;