import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { BUTTON_LABELS } from '../../lang/ja'; // Assuming the path is correct
import "./RejectResourceDialog.css";

// Define property types for clarity and error checking
const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  reason: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

// Define default props for default behaviour and error prevention
const defaultProps = {
  reason: '',
};

const RejectResourceDialog = (props) => {
  const { isOpen, reason, onClose, onReject } = props;
  const [localReason, setLocalReason] = React.useState(reason); // Local state for input control

  // Update local state when 'reason' prop changes
  React.useEffect(() => {
    setLocalReason(reason);
  }, [reason]);

  // Handles the submission of rejection reason
  const handleReject = () => {
    onReject(localReason); // Call the onReject function passed as a prop
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle>却下</DialogTitle> {/* Japanese for "rejection" */}
      <DialogContent>
        {/* Text area for the reason of rejection */}
        <textarea
          className='reject_resource_dialog_text_area'
          rows={3}
          autoFocus
          id="name"
          placeholder="却下理由"
          type="text"
          value={localReason}
          onChange={(e) => setLocalReason(e.target.value)}
          disabled={!!reason} // Disable input if 'reason' prop is provided
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {reason ? BUTTON_LABELS.BUTTON_CLOSE : BUTTON_LABELS.BUTTON_CANCEL} {/* Display text based on 'reason' prop */}
        </Button>
        {/* Show the reject button only if no 'reason' prop is provided */}
        {!reason && (
          <Button
            onClick={handleReject}
            disabled={!localReason}>
            {BUTTON_LABELS.BUTTON_REJECT}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

// Assign the defined prop types and default props
RejectResourceDialog.propTypes = propTypes;
RejectResourceDialog.defaultProps = defaultProps;

export default RejectResourceDialog;
