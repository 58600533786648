import { API } from 'aws-amplify';
import { v5 as uuidv5 } from 'uuid';
import { 
  getInternalResource as getInternalResourceCmd, 
  getExternalResource as getExternalResourceCmd, 
  findExternalResourcesSortByType,
  findInternalResourcesSortByType,
  listExternalResources as listExternalResourcesCmd,
  listInternalResources as listInternalResourcesCmd,
  findWebPagesSortByStatus
} from '../graphql/queries';
import {
  createExternalResource as createExternalResourceCmd,
  createInternalResource as createInternalResourceCmd,
  deleteWebPage as deleteWebPageCmd,
  updateExternalResource,
  updateInternalResource,
  updateWebPage as updateWebPageCmd,
  createWebPage as createWebPageCmd,
  processExternalResource as processExternalResourceCmd,
  processInternalResource as processInternalResourceCmd,
  deleteExternalResource as deleteExternalResourceCmd,
  deleteInternalResource as deleteInternalResourceCmd
} from '../graphql/mutations';
import { APP_AUTH_MODE } from '../constants/app';
import { EXECUTION_TYPES, PROCESS_TYPES, RESOURCE_STATUS, RESOURCE_TYPES } from '../constants/status';
import { Storage } from 'aws-amplify';
import { MESSAGES } from '../lang/ja';
import { getStatusKeyByLabel } from '../../src/utils/resources';

const listResources = async (governmentId, type=null, isExternal=true, nextToken=null, selectedStatus) => {
  const query = isExternal? 
    (type? findExternalResourcesSortByType: listExternalResourcesCmd): 
    (type? findInternalResourcesSortByType: listInternalResourcesCmd);
  const property = isExternal? 
    (type? 'findExternalResourcesSortByType': 'listExternalResources'): 
    (type? 'findInternalResourcesSortByType': 'listInternalResources');
  const params = {
    sortDirection: 'DESC',
    nextToken
  };
  params.governmentId = governmentId;
  if (type) {
    params.typeCreatedAt = {beginsWith: {type, createdAt: ''}};
  }

  const selectedStatusKey = getStatusKeyByLabel(selectedStatus)

  if(selectedStatusKey){
    params.filter = {
      status: { eq: selectedStatusKey } // ステータスに基づいてフィルタリング
    };
  }
  
  try {
    const res = await API.graphql({
      query,
      variables: params,
      authMode: APP_AUTH_MODE
    })
    if (res.data[property]) {
      const data = res.data[property];
      const items = data.items;
      for (const item of items) {
        if (item.executionDetail) {
          item.executionDetail = JSON.parse(item.executionDetail);
        }
      }
      return {
        success: true,
        data: items,
        nextToken: data.nextToken
      }
    }
    return {
      success: false,
      error: res.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listFiles = async(governmentId, isExternal=true, nextToken=null) => {
  return await listResources(governmentId, RESOURCE_TYPES.FILE, isExternal, nextToken);
}

const listWebs = async (governmentId, isExternal=true, nextToken=null) => {
  return await listResources(governmentId, RESOURCE_TYPES.WEBSITE, isExternal, nextToken);
}

const listFaqs = async (governmentId, isExternal, nextToken=null) => {
  return await listResources(governmentId, 'FAQ', isExternal, nextToken);
}

const _listAllFaqsInMonth = async (governmentId, month, isExternal) => {
  const query = isExternal? findExternalResourcesSortByType: findInternalResourcesSortByType;
  const property = isExternal? 'findExternalResourcesSortByType': 'findInternalResourcesSortByType';
  let nextToken = null;
  const params = {
    sortDirection: 'DESC',
    nextToken,
    governmentId,
    typeCreatedAt: {
      beginsWith: {
        type: 'FAQ',
        createdAt: month
      }
    }
  };
  const items = [];
  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      params.nextToken = nextToken;
      const res = await API.graphql({
        query,
        variables: params,
        authMode: APP_AUTH_MODE
      });
      items.push(...res.data[property].items);
      nextToken = res.data[property].nextToken;
      if (!nextToken) break;
    }
    return {
      success: true,
      data: items
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listAllInternalFaqsInMonth = async (governmentId, month) => {
  return await _listAllFaqsInMonth(governmentId, month, false)
}

const listAllExternalFaqsInMonth = async (governmentId, month) => {
  return await _listAllFaqsInMonth(governmentId, month, true)
}

const _createResource = async (id, governmentId, name, answer, url, srcMsgKey, type, isExternal=true) => {
  try {
    const params = {
      governmentId,
      name,
      url,
      type,
      status: type===RESOURCE_TYPES.FAQ || type===RESOURCE_TYPES.FILE? RESOURCE_STATUS.PENDING: RESOURCE_STATUS.PROCESS_REQUESTING,
      srcMsgKey,
      process: PROCESS_TYPES.CREATE,
      execution: EXECUTION_TYPES.IMMEDIATE
    };
    if (type===RESOURCE_TYPES.WEBSITE) {
      params.id = uuidv5(name, uuidv5.URL);
    }
    if (id) {
      params.id = id;
    }
    if (answer) params.answer = answer;
    const res = await API.graphql({
      query: isExternal? createExternalResourceCmd: createInternalResourceCmd,
      variables: {
        input: params
      },
      authMode: APP_AUTH_MODE
    });
    const data = res.data[isExternal? 'createExternalResource': 'createInternalResource'];
    if (!data) {
      return {
        success: false,
        error: res.errors
      }
    }
    return {
      success: true,
      data: data
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const _updateResource = async (governmentId, createdAt, type, newProps, isExternal=true) => {
  try {
    const res = await API.graphql({
      query: isExternal? updateExternalResource: updateInternalResource,
      variables: {
        input: {
          governmentId,
          createdAt,
          type,
          ...newProps
        }
      },
      authMode: APP_AUTH_MODE
    });
    const data = res.data[isExternal? 'updateExternalResource': 'updateInternalResource'];
    if (!data) {
      return {
        success: false,
        error: res.errors
      }
    }
    return {
      success: true,
      data: data
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const updateResource = async (resource, newProps={}) => {
  try {
    const newResource = Object.assign({}, resource, newProps);
    const isExternal = resource.__typename === 'ExternalResource';
    delete newResource.updatedAt;
    delete newResource.__typename;
    if (newResource.executionDetail) {
      newResource.executionDetail = JSON.stringify(newResource.executionDetail);
    }
    console.log("newResource",newResource)
    const res = await API.graphql({
      query: isExternal? updateExternalResource: updateInternalResource,
      variables: {
        input: newResource
      },
      authMode: APP_AUTH_MODE
    });
    const data = res.data[isExternal? 'updateExternalResource': 'updateInternalResource'];
    
    if (!data) {
      return {
        success: false,
        error: res.errors
      }
    }
    if (data.executionDetail) {
      data.executionDetail = JSON.parse(data.executionDetail);
    }
    console.log("data",data)
    return {
      success: true,
      data: data
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}


const createFile = async (id, governmentId, name, isExternal) => {
  return await _createResource(id, governmentId, name, null, null, null, 'FILE', isExternal);
}

const createFaq = async (governmentId, name, answer, url=null, srcMsgKey=null, isExternal=true) => {
  return await _createResource(null, governmentId, name, answer, url, srcMsgKey, 'FAQ', isExternal);
}

const _getWeb = async (governmentId, url, isExternal) => {
  try {
    let nextToken = null;
    const items = [];
    const params = {
      governmentId,
      typeCreatedAt: {
        beginsWith: {
          type: 'WEBSITE',
          createdAt: ''
        }
      },
      filter: {
        name: {
          eq: url
        }
      }
    };
    const query = isExternal? findExternalResourcesSortByType: findInternalResourcesSortByType;
    const property = isExternal? 'findExternalResourcesSortByType': 'findInternalResourcesSortByType';
    // eslint-disable-next-line no-constant-condition
    while (true) {
      params.nextToken = nextToken;
      const res = await API.graphql({
        query,
        variables: params,
        authMode: APP_AUTH_MODE
      });
      nextToken = res.data[property].nextToken;
      items.push(...res.data[property].items);
      if (items.length || !nextToken) break;
    }
    return {
      success: true,
      data: items
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const createWeb = async (governmentId, url, isExternal) => {
  const sameUrlRes = await _getWeb(governmentId, url, isExternal);
  if (!sameUrlRes.success) {
    return sameUrlRes;
  }
  else if (sameUrlRes.data.length) {
    return {
      success: false,
      error: MESSAGES.URL_ALREADY_REGISTERED
    }
  }
  return await _createResource(null, governmentId, url, null, null, null, RESOURCE_TYPES.WEBSITE, isExternal);
}

const updateWeb = async (resource, url) => {
  return await updateResource(resource, {name: url, status: RESOURCE_STATUS.PENDING, rejectReason: ''});
}

const _getResource = async (governmentId, createdAt, isExternal=true) => {
  const query = isExternal? getExternalResourceCmd: getInternalResourceCmd;
  const property = isExternal? 'getExternalResource': 'getInternalResource';
  try {
    const res = await API.graphql({
      query,
      variables: {
        governmentId,
        createdAt
      },
      authMode: APP_AUTH_MODE
    })
    if (res.data[property]) {
      return {
        success: true,
        data: res.data[property]
      }
    }
    return {
      success: false,
      error: res.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const getExternalResource = async (governmentId, createdAt) => {
  return await _getResource(governmentId, createdAt, true);
}
const getInternalResource = async (governmentId, createdAt) => {
  return await _getResource(governmentId, createdAt, false);
}

const processResource = async (resource) => {
  try {
    const isExternal = resource.__typename === 'ExternalResource';
    const query = isExternal? processExternalResourceCmd: processInternalResourceCmd;
    const property = isExternal? 'processExternalResource': 'processInternalResource';
    const res = await API.graphql({
      query,
      variables: {
        input: {
          type: resource.type,
          governmentId: resource.governmentId,
          createdAt: resource.createdAt
        }
      },
      authMode: APP_AUTH_MODE
    })
    if (res.data) {
      return res.data[property];
    }
    return {
      success: false,
      error: res.errors
    } 
  }
  catch (e) {
    console.log('VANVIET', e);
    return {
      success: false,
      error: e
    }
  }
}
const startProcessingResource = async (resource) => {
  if (resource.execution===EXECUTION_TYPES.SCHEDULED || resource.execution===EXECUTION_TYPES.RECURRING) {
    return await updateResource(resource, {status: RESOURCE_STATUS.WAITING, process: PROCESS_TYPES. CREATE});  
  }
  return await updateResource(resource, {status: RESOURCE_STATUS.PROCESSING, process: PROCESS_TYPES. CREATE});
}
const requestProcessingResource = async (resource) => {
  return await updateResource(resource, {
    status: RESOURCE_STATUS.PROCESS_REQUESTING, 
    rejectReason: ''
  })
}
const requestDeletingResource = async (resource) => {
  return await updateResource(resource, {
    status: RESOURCE_STATUS.DELETE_REQUESTING, 
    process: PROCESS_TYPES.DELETE,
    rejectReason: ''
  })
}
const requestReprocessingResource = async (resource) => {
  return await updateResource(resource, {status: RESOURCE_STATUS.REPROCESS_REQUESTING, rejectReason: ''});
}
const rejectProcessingResource = async (resource, rejectReason) => {
  if (resource.type!==RESOURCE_TYPES.FILE) {
    return await updateResource(resource, {status: RESOURCE_STATUS.PROCESS_REJECTED, rejectReason});
  }
  return {
    success: false,
    error: 'Cannot reject processing a file'
  }
}
const rejectDeletingResource = async (resource, rejectReason) => {
  return await updateResource(resource,
    {
      status: RESOURCE_STATUS.FINISHED, 
      process: PROCESS_TYPES.CREATE,
      rejectReason
    }
  );
}
const startDeletingResource = async (resource) => {
  if (resource.execution===EXECUTION_TYPES.SCHEDULED || resource.execution===EXECUTION_TYPES.RECURRING) {
    return await updateResource(resource, {status: RESOURCE_STATUS.WAITING, process: PROCESS_TYPES. DELETE});  
  }
  return await updateResource(resource, {status: RESOURCE_STATUS.PROCESSING, process: PROCESS_TYPES.DELETE});
}
const searchWebPages = async (baseUrlId, keyword, status, minNum, nextToken) => {
  try {
    let tempNextToken = nextToken;
    const params = {
      baseUrlId
    };
    if (status) {
      params.status = { eq: status };
    }
    if (keyword) {
      params.filter = { detailUrl: { contains: keyword } };
    }
    const items = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const res = await API.graphql({
        query: findWebPagesSortByStatus,
        variables: { ...params, nextToken: tempNextToken },
        authMode: APP_AUTH_MODE
      });
      tempNextToken = res.data.findWebPagesSortByStatus.nextToken;
      items.push(...res.data.findWebPagesSortByStatus.items);
      if (!tempNextToken || items.length >= minNum) break;
    }
    return {
      success: true,
      data: items,
      nextToken: tempNextToken
    };
  } catch (e) {
    return {
      success: false,
      error: e
    };
  }
};

const _updateWebPage = async (baseUrlId, detailUrl, status, reason=null) => {
  try {
    const params = {
      baseUrlId,
      detailUrl,
      status
    };
    if (reason || reason==='') {
      params.rejectReason = reason;
    }
    const res = await API.graphql({
      query: updateWebPageCmd,
      variables: {
        input: params
      },
      authMode: APP_AUTH_MODE
    })
    return {
      success: true,
      data: res.data.updateWebPage
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const requestProcessingWebPage = async (baseUrlId, detailUrl) => {
  return await _updateWebPage(baseUrlId, detailUrl, RESOURCE_STATUS.PROCESS_REQUESTING, '');
}
const requestReprocessingWebPage = async (baseUrlId, detailUrl) => {
  return await _updateWebPage(baseUrlId, detailUrl, RESOURCE_STATUS.REPROCESS_REQUESTING, '');
}
const requestDeletingWebPage = async (baseUrlId, detailUrl) => {
  return await _updateWebPage(baseUrlId, detailUrl, RESOURCE_STATUS.DELETE_REQUESTING, '');
}
const createWebPage = async (baseUrlId, detailUrl, URLtype) => {
  try {
    const res = await API.graphql({
      query: createWebPageCmd,
      variables: {
        input: {
          id: uuidv5(detailUrl, uuidv5.URL),
          baseUrlId,
          detailUrl,
          type: URLtype,
          status: 'PENDING'
        }
      },
      authMode: APP_AUTH_MODE
    })
    return {
      success: true,
      data: res.data.createWebPage
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const updateWebPage = async (baseUrlId, detailUrl, newDetailUrl) => {
  try {
    await API.graphql({
      query: deleteWebPageCmd,
      variables: {
        input: {
          baseUrlId,
          detailUrl
        }
      },
      authMode: APP_AUTH_MODE
    });
    const res = await API.graphql({
      query: createWebPageCmd,
      variables: {
        input: {
          baseUrlId,
          detailUrl: newDetailUrl,
          status: 'PENDING'
        }
      },
      authMode: APP_AUTH_MODE
    });
    return {
      success: true,
      data: res.data.createWebPage
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const updateBatchRecurringWebPage = async (resource, newProps={}) => {
  try {
    const newResource = Object.assign({}, resource, newProps);
    delete newResource.updatedAt;
    delete newResource.createdAt;
    delete newResource.rejectReason;
    delete newResource.__typename;
    if (newResource.executionDetail) {
      newResource.executionDetail = JSON.stringify(newResource.executionDetail);
    }
    console.log("newResource",newResource)
    const res = await API.graphql({
      query: updateWebPageCmd,
      variables: {
        input: newResource
      },
      authMode: APP_AUTH_MODE
    });
    const data = res.data.updateWebPage;
    
    console.log("data",data)
    if (!data) {
      return {
        success: false,
        error: res.errors
      }
    }
    if (data.executionDetail) {
      data.executionDetail = JSON.parse(data.executionDetail);
    }
    console.log("data",data)
    return {
      success: true,
      data: data
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const rejectProcessingWebpage = async (baseUrlId, detailUrl, rejectReason) => {
  return await _updateWebPage(baseUrlId, detailUrl, RESOURCE_STATUS.PROCESS_REJECTED, rejectReason);
}
// eslint-disable-next-line no-unused-vars
const rejectDeletingWebpage = async (baseUrlId, detailUrl, rejectReason) => {
  
}
const rejectReprocessingWebpage = async (baseUrlId, detailUrl) => {
  return await _updateWebPage(baseUrlId, detailUrl, RESOURCE_STATUS.PENDING, '');
}
const processManualWebPage = async (governmentId, baseUrl, detailUrl, isExternal) => {
  try {
    const path = isExternal? '/processExternalManualWebpage': '/processInternalManualWebpage'
    const res = await API.post('scrapebatch', path, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, GET, POST, PUT, DELETE, OPTIONS',
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: {
        queryType: 'PROCESS',
        governmentId,
        baseUrl,
        detailUrl
      }
    });
    return res;
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const adminDeleteResource = async (resource) => {
  return await processResource(resource);
}
const deleteResource = async (resource) => {
  try {
    const isExternal = resource.__typename==='ExternalResource';
    const input = {
      governmentId: resource.governmentId,
      createdAt: resource.createdAt
    };
    const res = await API.graphql({
      query: isExternal ? deleteExternalResourceCmd : deleteInternalResourceCmd,
      variables: { input },
      authMode: APP_AUTH_MODE,
    });
    const data = res?.data[isExternal ? 'deleteExternalResource' : 'deleteInternalResource'];
    console.log("VANVIET 2", res)
    if (!data) {
      return {
        success: false,
        error: res?.errors,
      };
    }
    if (data.type==='FILE') {
      const extension = data.name.substr(data.name.lastIndexOf('.'));
      await Storage.remove(`${resource.governmentId}/${isExternal? 'external': 'internal'}/file/${data.id}${extension}`);
    }
    console.log("VANVIET 3", data)
    return {
      success: true,
      data: data,
    };
  } catch (e) {
    console.log("VANVIET 4", e)
    return {
      success: false,
      error: e,
    };
  }
}
const adminDeleteWebpage = async (governmentId, baseUrl, detailUrl, isExternal) => {
  try {
    const path = isExternal? '/processExternalManualWebpage': '/processInternalManualWebpage'
    const res = await API.post('scrapebatch', path, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: {
        queryType: 'DELETE',
        governmentId,
        baseUrl,
        detailUrl
      }
    });
    return res;
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const startDeletingWebpage = async (baseUrlId, detailUrl) => {
  return await _updateWebPage(baseUrlId, detailUrl, 'DELETING');
}
const deleteWebPage = async (baseUrlId, detailUrl) => {
  try {
    const res = await API.graphql({
      query: deleteWebPageCmd,
      variables: {
        input: {
          baseUrlId,
          detailUrl
        }
      },
      authMode: APP_AUTH_MODE
    });
    return {
      success: true,
      error: res.data.deleteWebPage
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const updateExecution = async (resource, execution, executionDetail) => {
  delete resource.updatedAt;
  resource.execution = execution;
  resource.executionDetail = JSON.stringify(executionDetail);
  const isExternal = resource.__typename=='ExternalResource';
  delete resource.__typename;
  return await _updateResource(resource.governmentId, resource.createdAt, resource.type, resource, isExternal);
}
export {
  listResources,
  listFiles,
  listFaqs,
  listWebs,
  listAllExternalFaqsInMonth,
  listAllInternalFaqsInMonth,
  createFaq,
  createFile,
  createWeb,
  updateWeb,
  getExternalResource, 
  getInternalResource,
  processResource,
  startProcessingResource,
  requestProcessingResource,
  requestDeletingResource,
  requestReprocessingResource,
  rejectProcessingResource,
  rejectDeletingResource,
  startDeletingResource,
  searchWebPages,
  createWebPage,
  updateWebPage,
  updateBatchRecurringWebPage,
  requestProcessingWebPage,
  requestReprocessingWebPage,
  requestDeletingWebPage,
  rejectProcessingWebpage,
  rejectReprocessingWebpage,
  rejectDeletingWebpage,
  processManualWebPage,
  adminDeleteResource,
  deleteResource,
  adminDeleteWebpage,
  startDeletingWebpage,
  deleteWebPage,
  updateResource,
  updateExecution
};