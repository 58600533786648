import React from "react";
import "./FAQTable.css";
// react-bootstrap components
import moment from "moment";
import Papa from "papaparse";
import { Button, Card, Col, Dropdown, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getProcessTime, getStatusLabel } from "utils/resources";
import { getExternalMessage, getInternalMessage } from "../../apis/MessageApi";
import {
  createFaq,
  deleteResource,
  listAllExternalFaqsInMonth,
  listAllInternalFaqsInMonth,
  listFaqs,
  requestDeletingResource,
  requestProcessingResource,
  requestReprocessingResource,
  updateResource,
} from "../../apis/ResourceApi";
import BatchModalDialog from "../../components/BatchModalDialog";
import FanLoadingIcon from "../../components/FanLoadingIcon";
import useCsvDownloader from "../../components/Hooks/useCsvDownloader";
import PaginationComponent from "../../components/PaginationComponent";
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import { RESOURCE_STATUS } from "../../constants/status";
import { NUM_ITEMS_PER_PAGE } from "../../constants/table";
import { useDialog } from "../../contexts/DialogContext";
import { BUTTON_LABELS, MESSAGES } from "../../lang/ja";
import UpdateAnswerDialog from "../../pages/qa/UpdateAnswerDialog";
import RejectResourceDialog from "../../pages/resource/RejectResourceDialog";
import FileRegisterDialog from "../../views/Dialog/FileRegisterDialog";
import CSVDownloadDialog from "./CSVDownloadDialog";
import FAQRegisterDialog from "./FAQRegisterDialog";

function FAQTable() {
  /***** States and Variables *****/
  const [faqs, setFaqs] = React.useState([]);
  const [selectedFaq, setSelectedFaq] = React.useState(null);
  const [selectedFaqForBatch, setSelectedFaqForBatch] = React.useState(null);
  const [rejectedFaq, setRejectedFaq] = React.useState(null);
  const [csvRegisterDialogOpen, setCsvRegisterDialogOpen] = React.useState(false);
  const [csvDownloadDialogOpen, setCsvDownloadDialogOpen] = React.useState(false);
  const { userInfo } = useSelector((state) => state.users);
  const governmentId = userInfo?.governmentId;
  const [curPage, setCurPage] = React.useState(0);
  const numPages = Math.ceil(faqs.length / NUM_ITEMS_PER_PAGE);
  const nextTokenRef = React.useRef(null);
  const [sourceMsg, setSourceMsg] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { success, error } = useDialog();
  const { downloadCsv } = useCsvDownloader();
  const handleSetScheduled = (faq) => {
    console.log("type faq", faq);
    setSelectedFaqForBatch(faq);
  };
  /***** Processing *****/
  React.useEffect(() => {
    const downloadFaqs = async () => {
      const res = await listFaqs(governmentId, APP_TYPE === APP_TYPES.OUTSIDE_ADMIN, null);
      if (res.success) {
        setFaqs(res.data);
        nextTokenRef.current = res.nextToken;
      }
    };
    if (governmentId) downloadFaqs();
  }, [governmentId]);
  /***** Event Handlers *****/
  const onRegisterFaq = async (question, answer, url) => {
    if (loading) return;
    setLoading(true);
    let res;
    if (!selectedFaq.createdAt) {
      res = await createFaq(governmentId, question, answer, url, null, APP_TYPE === APP_TYPES.OUTSIDE_ADMIN);
    } else {
      const newProps = { name: question, answer, url, rejectReason: "" };
      if (selectedFaq.status !== RESOURCE_STATUS.PENDING) {
        newProps.status = RESOURCE_STATUS.PROCESS_REQUESTING;
      }
      res = await updateResource(selectedFaq, newProps);
    }
    if (res.success) {
      if (!selectedFaq.createdAt) {
        setFaqs([res.data, ...faqs]);
      } else {
        faqs.splice(
          faqs.findIndex((faq) => faq.createdAt === selectedFaq.createdAt),
          1,
          res.data
        );
        setFaqs(Object.assign([], faqs));
      }
      setSelectedFaq(null);
    }
    setLoading(false);
  };
  const onPageChange = async (page) => {
    setCurPage(page);
    if (page + 6 > numPages) {
      const nextToken = nextTokenRef.current;
      if (nextToken) {
        const res = await listFaqs(governmentId, APP_TYPE === APP_TYPES.OUTSIDE_ADMIN, nextToken);
        if (res.success) {
          if (nextToken) {
            faqs.push(...res.data);
            setFaqs(Object.assign([], faqs));
          } else {
            setFaqs(res.data);
          }
          nextTokenRef.current = res.nextToken;
        }
      }
    }
  };
  const onClickQa = async (faq) => {
    if (!faq.srcMsgKey) return;
    const srcMsgKey = JSON.parse(faq.srcMsgKey);
    const res = await (APP_TYPE === APP_TYPES.INSIDE_ADMIN
      ? getInternalMessage(srcMsgKey.conversationId, srcMsgKey.createdAt)
      : getExternalMessage(srcMsgKey.conversationId, srcMsgKey.createdAt));
    if (res.success) {
      const msg = res.data;
      setSourceMsg({
        question: faq.name,
        fixAnswer: faq.answer,
        aiAnswer: msg.text + "\n" + msg.url,
        fixUrl: faq.url,
        answerCreatedAt: faq.createdAt,
      });
    }
  };
  const onUpdateFaq = async (newAnswer, newUrl) => {
    const faq = faqs.find((f) => f.createdAt === sourceMsg.answerCreatedAt);
    if (!faq) return;
    const newProps = { name: sourceMsg.name, answer: newAnswer, url: newUrl };
    if (faq.status !== RESOURCE_STATUS.PENDING) {
      newProps.status = RESOURCE_STATUS.PROCESS_REQUESTING;
    }
    const res = await updateResource(faq, newProps);
    if (res.success) {
      const index = faqs.indexOf(faq);
      faqs.splice(index, 1, res.data);
      setFaqs(Object.assign([], faqs));
    }
    setSourceMsg(null);
  };
  const onRequestDelete = async (faq) => {
    if (loading) return;
    setLoading(true);
    const res = await requestDeletingResource(faq);
    if (res.success) {
      const pos = faqs.indexOf(faq);
      if (pos >= 0) {
        faqs.splice(pos, 1, res.data);
        setFaqs(Object.assign([], faqs));
      }
    }
    setLoading(false);
  };
  const onRequestProcess = async (faq) => {
    if (loading) return;
    setLoading(true);
    const res = await requestProcessingResource(faq);
    if (res.success) {
      const pos = faqs.indexOf(faq);
      if (pos >= 0) {
        faqs.splice(pos, 1, res.data);
        setFaqs(Object.assign([], faqs));
      }
    }
    setLoading(false);
  };
  const onRequestReprocess = async (faq) => {
    if (loading) return;
    setLoading(true);
    const res = await requestReprocessingResource(faq);
    if (res.success) {
      const pos = faqs.indexOf(faq);
      if (pos >= 0) {
        faqs.splice(pos, 1, res.data);
        setFaqs(Object.assign([], faqs));
      }
    }
    setLoading(false);
  };
  const onDeleteFaq = async (faq) => {
    if (loading) return;
    setLoading(true);
    const res = await deleteResource(faq);
    if (res.success) {
      const pos = faqs.indexOf(faq);
      faqs.splice(pos, 1);
      setFaqs(Object.assign([], faqs));
      success(MESSAGES.DELETED_FAQ_SUCCESSFULLY);
    } else {
      error(MESSAGES.SOMETHING_WRONG);
    }
    setLoading(false);
  };
  const onRegisterCsv = async (files) => {
    setCsvRegisterDialogOpen(false);
    if (loading) return;
    let newFaqs = faqs;
    for (const file of files) {
      Papa.parse(file, {
        worker: true,
        async complete({ data }) {
          setLoading(true);
          let questionPos, answerPos, urlPos;
          for (const item of data) {
            if (item === data[0]) {
              questionPos = item.indexOf("question");
              answerPos = item.indexOf("answer");
              urlPos = item.indexOf("url");
            } else if (item[0] && item[1]) {
              const res = await createFaq(
                governmentId,
                item[questionPos],
                item[answerPos],
                item[urlPos],
                null,
                APP_TYPE === APP_TYPES.OUTSIDE_ADMIN
              );
              console.log("VANVIET", res);
              if (res.success) {
                newFaqs = [res.data, ...newFaqs];
                setFaqs(newFaqs);
              }
            }
          }
          setLoading(false);
        },
      });
    }
  };
  const onDownloadCsv = async (month) => {
    setCsvDownloadDialogOpen(false);
    setLoading(true);
    const res = await (APP_TYPE === APP_TYPES.INSIDE_ADMIN
      ? listAllInternalFaqsInMonth(governmentId, month)
      : listAllExternalFaqsInMonth(governmentId, month));
    if (res.success) {
      let data = res.data.map((faq) => {
        return [faq.name, faq.answer, faq.url, faq.createdAt];
      });
      data = [["question", "answer", "url", "createdAt"], ...data];
      await downloadCsv(data, "faq_" + month + ".csv");
    } else {
      error(MESSAGES.SOMETHING_WRONG);
    }
    setLoading(false);
  };
  const onDownloadTemplate = async () => {
    setCsvDownloadDialogOpen(false);
    setLoading(true);
    const data = [
      ["question", "answer", "url"],
      ["質問", "答えです", "https://www.example.com/"],
    ];
    await downloadCsv(data, "faq_template.csv");
    setLoading(false);
  };

  const onUpdateResourceExecution = async (executionType, executionDetail) => {
    if (loading) return;
    setLoading(true);
    const faq = selectedFaqForBatch;
    const res = await updateResource(faq, { execution: executionType, executionDetail });

    if (res.success) {
      success(MESSAGES.EXECUTION_UPDATED_SUCCESSFULLY);
      faqs.splice(faqs.indexOf(selectedFaqForBatch), 1, res.data);
      setFaqs(Object.assign([], faqs));
    } else {
      error(MESSAGES.SOMETHING_WRONG);
    }
    setLoading(false);
    setSelectedFaqForBatch(null);
  };

  /***** Main Render *****/
  return (
    <>
      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">データ管理 &gt; FAQ</Card.Title>
            <div className="d-flex flex-row justify-content-between align-items-end">
              <PaginationComponent currentPage={curPage} totalItems={faqs.length} onPageChange={onPageChange} />
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: "#0062cc",
                    }}
                  >
                    登録 - DL
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="faq_table_dropdown_item"
                      onClick={() => {
                        setSelectedFaq({});
                      }}
                    >
                      {BUTTON_LABELS.BUTTON_REGISTER_NEW}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="faq_table_dropdown_item"
                      onClick={() => {
                        setCsvRegisterDialogOpen(true);
                      }}
                    >
                      {BUTTON_LABELS.BUTTON_REGISTER_CSV}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="faq_table_dropdown_item"
                      onClick={() => {
                        setCsvDownloadDialogOpen(true);
                      }}
                    >
                      {BUTTON_LABELS.BUTTON_DOWNLOAD}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="table-responsive p-0 overflow-auto">
            <Table className="table-hover table-striped w-full">
              <thead>
                <tr>
                  <th>質問</th>
                  <th>更新日時</th>
                  <th>反映予定日時</th>
                  <th>ステータス</th>
                  <th>アクション</th>
                </tr>
              </thead>
              <tbody>
                {faqs.map((faq, i) => {
                  if (i < curPage * NUM_ITEMS_PER_PAGE || i >= (curPage + 1) * NUM_ITEMS_PER_PAGE) return null;
                  const status = faq.status;
                  // eslint-disable-next-line react/jsx-key
                  return (
                    <tr key={i}>
                      <td>{faq.name}</td>
                      <td>{moment(faq.updatedAt).format("yyyy/MM/DD HH:mm")}</td>
                      <td>{getProcessTime(faq)}</td>
                      <td>
                        {getStatusLabel(faq)}
                        {faq.rejectReason && (
                          <button
                            className="button_reject_reason"
                            onClick={() => {
                              setRejectedFaq(faq);
                            }}
                          >
                            !
                          </button>
                        )}
                      </td>
                      <td>
                        {/***** QA *****/}
                        {faq.srcMsgKey && (
                          <Button
                            className="btn-outline mr-1"
                            variant="default"
                            onClick={() => {
                              onClickQa(faq);
                            }}
                          >
                            QA
                          </Button>
                        )}
                        {/***** EDIT *****/}
                        {(status === RESOURCE_STATUS.PENDING ||
                          status === RESOURCE_STATUS.PROCESS_REJECTED ||
                          status === RESOURCE_STATUS.FINISHED ||
                          status === RESOURCE_STATUS.FAILED) && (
                          <Button
                            className="btn-outline mr-1"
                            variant="default"
                            onClick={() => {
                              setSelectedFaq(faq);
                            }}
                          >
                            修正
                          </Button>
                        )}
                        {/***** REQUEST PROCESS *****/}
                        {(status === RESOURCE_STATUS.PENDING || status === RESOURCE_STATUS.PROCESS_REJECTED) && (
                          <Button
                            className="btn-outline mr-1"
                            variant="default"
                            onClick={() => {
                              onRequestProcess(faq);
                            }}
                          >
                            {BUTTON_LABELS.BUTTON_REQUEST_PROCESS}
                          </Button>
                        )}
                        {/***** REQUEST REPROCESS *****/}
                        {status === RESOURCE_STATUS.FAILED && (
                          <Button
                            className="btn-outline mr-1"
                            variant="default"
                            onClick={() => {
                              onRequestReprocess(faq);
                            }}
                          >
                            {BUTTON_LABELS.BUTTON_REQUEST_REPROCESS}
                          </Button>
                        )}
                        {/***** REQUEST DELETE *****/}
                        {status === RESOURCE_STATUS.FINISHED && (
                          <Button
                            className="btn-outline mr-1"
                            variant="default"
                            onClick={() => {
                              onRequestDelete(faq);
                            }}
                          >
                            {BUTTON_LABELS.BUTTON_REQUEST_DELETE}
                          </Button>
                        )}
                        {/***** DELETE *****/}
                        {status === RESOURCE_STATUS.PENDING && (
                          <Button
                            className="btn-outline mr-1"
                            variant="default"
                            onClick={() => {
                              onDeleteFaq(faq);
                            }}
                          >
                            {BUTTON_LABELS.BUTTON_DELETE}
                          </Button>
                        )}
                        {/***** SET SCHEDULED *****/}
                        <Button
                          className="btn-outline mr-1"
                          variant="default"
                          onClick={() => {
                            handleSetScheduled(faq);
                          }}
                        >
                          {BUTTON_LABELS.BUTTON_SET_SCHEDULED}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      <FAQRegisterDialog
        isOpen={!!selectedFaq}
        faq={selectedFaq}
        onClose={() => {
          setSelectedFaq(null);
        }}
        onRegister={onRegisterFaq}
      />
      <BatchModalDialog
        isOpen={!!selectedFaqForBatch}
        isCreateDialog={selectedFaqForBatch?.status === RESOURCE_STATUS.FINISHED}
        onClose={() => {
          setSelectedFaqForBatch(null);
        }}
        enableRecurring={false}
        onSubmit={onUpdateResourceExecution}
        initExecutionType={selectedFaqForBatch?.execution}
        initExecutionDetail={selectedFaqForBatch?.executionDetail}
        editable={
          selectedFaqForBatch?.status !== RESOURCE_STATUS.PROCESSING &&
          selectedFaqForBatch?.status !== RESOURCE_STATUS.WAITING
        }
      />
      <FileRegisterDialog
        isOpen={csvRegisterDialogOpen}
        onClose={() => {
          setCsvRegisterDialogOpen(false);
        }}
        accept={[".csv"]}
        onRegister={onRegisterCsv}
        hasTemplate={true}
        onDownloadTemplate={onDownloadTemplate}
      />
      <CSVDownloadDialog
        isOpen={csvDownloadDialogOpen}
        onClose={() => setCsvDownloadDialogOpen(false)}
        onDownload={onDownloadCsv}
      />
      <UpdateAnswerDialog
        isOpen={!!sourceMsg}
        question={sourceMsg?.question}
        answer={sourceMsg?.fixAnswer}
        aiAnswer={sourceMsg?.aiAnswer}
        url={sourceMsg?.fixUrl}
        onClose={() => {
          setSourceMsg(null);
        }}
        onUpdate={onUpdateFaq}
      />
      <RejectResourceDialog
        isOpen={!!rejectedFaq}
        onClose={() => {
          setRejectedFaq(null);
        }}
        reason={rejectedFaq?.rejectReason}
      />
      {loading && <FanLoadingIcon size={80} />}
    </>
  );
}

export default FAQTable;
