import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Card, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import AccountForm from "../../components/AccountForm";
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import { registerUser } from "../../features/user/userActions";
import { COGNITO_SUCCESS_MESSAGES } from "../../lang/ja.js";
import { registerAccountSchema } from "../../utils/validationSchemas";

function CreateAccount() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { governmentId } = useParams();
  const { loading } = useSelector((state) => state.users);
  const [errorState, setErrorState] = React.useState(null);

  //バリデーションのエラーメッセージが不完全なので修正必要
  // eslint-disable-next-line no-unused-vars
  const {
    // eslint-disable-next-line no-empty-pattern
    formState: {},
  } = useForm({
    resolver: yupResolver(registerAccountSchema),
  });

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      roles: [data.role],
      role: undefined,
      enabled: true,
    };

    const cognitoResult = await dispatch(registerUser({ ...newData, governmentId }));
    if (cognitoResult.payload.message == COGNITO_SUCCESS_MESSAGES.UserCreateSuccess) {
      history.goBack();
    } else {
      setErrorState(cognitoResult.payload);
    }
  };

  return (
    <Col md="12">
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">ユーザーアカウント作成</Card.Title>
        </Card.Header>
        <Card.Body className="table-responsive p-4">
          <AccountForm
            onSubmit={onSubmit}
            defaultValues={{
              username: "",
              email: "",
              password: "",
              role: APP_TYPE === APP_TYPES.OUTSIDE_ADMIN ? "outsideAdminStaff" : "staff",
            }}
            resolver={registerAccountSchema}
            loading={loading}
            error={errorState}
          />
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CreateAccount;
