import FanLoadingIcon from "components/FanLoadingIcon";
import moment from "moment";
import WebPageTable from "pages/web/WebPageTable";
import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Dropdown, DropdownButton, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getProcessTime, getStatusLabel } from "utils/resources";
import {
  adminDeleteResource,
  listResources,
  processResource,
  rejectDeletingResource,
  rejectProcessingResource,
  startDeletingResource,
  startProcessingResource,
  updateResource,
} from "../../apis/ResourceApi";
import BatchModalDialog from "../../components/BatchModalDialog";
import PaginationComponent from "../../components/PaginationComponent";
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import { EXECUTION_TYPES, PROCESS_TYPES, RESOURCE_STATUS, RESOURCE_TYPES } from "../../constants/status";
import { NUM_ITEMS_PER_PAGE } from "../../constants/table";
import { useDialog } from "../../contexts/DialogContext";
import { BUTTON_LABELS, MESSAGES, RESOURCE_TYPE_LABELS } from "../../lang/ja";
import EditResourceDialog from "./EditResourceDialog";
import RejectResourceDialog from "./RejectResourceDialog";
import "./index.css";

const propTypes = {};
const defaultProps = {};

const ResourcePage = () => {
  const [resources, setResources] = useState([]);
  const { userInfo } = useSelector((state) => state.users);
  const cityId = userInfo?.governmentId;
  const [curPage, setCurPage] = useState(0);
  const numPages = Math.ceil(resources.length / NUM_ITEMS_PER_PAGE);
  const nextTokenRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedWeb, setSelectedWeb] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ステータス検索");
  const [processRejectedResource, setProcessRejectedResource] = useState(null);
  const [deleteRejectedResource, setDeleteRejectedResource] = useState(null);
  const [scheduledResource, setScheduledResource] = useState(null);
  const { success, error } = useDialog();

  const downloadResources = async () => {
    const res = await listResources(cityId, null, APP_TYPE === APP_TYPES.OUTSIDE_ADMIN, null, selectedStatus);
    if (res.success) {
      setResources(res.data);
      nextTokenRef.current = res.nextToken;
    }
  };

  useEffect(() => {
    if (cityId) downloadResources();
  }, [cityId, selectedStatus]);

  const onPageChange = async (page) => {
    setCurPage(page);
    if (page + 6 > numPages) {
      const nextToken = nextTokenRef.current;
      if (nextToken) {
        const res = await listResources(cityId, null, APP_TYPE === APP_TYPES.OUTSIDE_ADMIN, nextToken, selectedStatus);
        if (res.success) {
          setResources([...resources, ...res.data]);
          nextTokenRef.current = res.nextToken;
        }
      }
    }
  };

  const onProcessResource = async (resource) => {
    if (loading) return;
    setLoading(true);
    const pos = resources.indexOf(resource);
    if (pos >= 0) {
      const startRes = await startProcessingResource(resource);
      if (startRes.success) {
        resources.splice(pos, 1, startRes.data);
        const newResources = Object.assign([], resources);
        setResources(newResources);
        if (resource.execution === EXECUTION_TYPES.IMMEDIATE) {
          const isWeb = resource.type === "WEBSITE";
          const processRes = await processResource(resource);
          if (!isWeb) {
            if (processRes.success) {
              newResources.splice(pos, 1, processRes.data);
              setResources(Object.assign([], newResources));
            } else {
              console.log("RES", processRes);
              error(MESSAGES.SOMETHING_WRONG);
            }
          } else {
            success(MESSAGES.LONGTIME_PROCESS);
          }
        }
      } else {
        // TODO: error handling, but this cannot happen
        // except timeout on lambda
        console.log("RES", startRes);
        error(MESSAGES.SOMETHING_WRONG);
      }
    }
    setLoading(false);
  };

  const onUpdatedResource = (newResource) => {
    const index = resources.findIndex((r) => r.createdAt === newResource.createdAt);
    if (index >= 0) {
      resources.splice(index, 1, newResource);
      setResources(Object.assign([], resources));
    }
  };

  const onRejectProcessResource = async (resource, reason) => {
    if (loading) return;
    setLoading(true);
    if (resource === processRejectedResource) {
      const res = await rejectProcessingResource(resource, reason);
      if (res.success) {
        const pos = resources.indexOf(resource);
        if (pos >= 0) {
          resources.splice(pos, 1, res.data);
          setResources([...resources]);
        }
      }
      setProcessRejectedResource(null);

    } else {
      const res = await rejectDeletingResource(resource, reason);
      if (res.success) {
        const pos = resources.indexOf(resource);
        if (pos >= 0) {
          resources.splice(pos, 1, res.data);
          setResources([...resources]);
        }
      }
      setDeleteRejectedResource(null);
    }

    setLoading(false);
  };

  const handleDeleteResource = async (resource) => {
    if (loading) return;
    setLoading(true);
    const startRes = await startDeletingResource(resource);
    if (startRes.success) {
      const pos = resources.indexOf(resource);
      resources.splice(pos, 1, startRes.data);
      const startResources = Object.assign([], resources);
      setResources(startResources);
      if (resource.execution === EXECUTION_TYPES.IMMEDIATE) {
        const isWeb = resource.type === "WEBSITE";
        const deleteRes = await adminDeleteResource(resource);
        if (deleteRes.success) {
          if (!isWeb) {
            success(MESSAGES.DELETED_DATA_SUCCESSFULLY);
            startResources.splice(pos, 1);
            const remainedResouces = Object.assign([], startResources);
            setResources(remainedResouces);
          }
        } else {
          error(MESSAGES.SOMETHING_WRONG);
        }
      }
    } else {
      error(MESSAGES.SOMETHING_WRONG);
    }
    setLoading(false);
  };

  const statusLabels = [
    "編集中",
    "ロード依頼",
    "再実行依頼",
    "ロード待ち",
    "ロード中",
    "ロード却下",
    "エラー",
    "ロード済み",
    "検索をリセット",
  ];

  const onUpdateExecution = async (executionType, executionDetail) => {
    if (loading) return;
    setLoading(true);
    const res = await updateResource(scheduledResource, { execution: executionType, executionDetail });
    if (res.success) {
      success(MESSAGES.EXECUTION_UPDATED_SUCCESSFULLY);
      resources.splice(resources.indexOf(scheduledResource), 1, res.data);
      setResources(Object.assign([], resources));
    } else {
      error(MESSAGES.SOMETHING_WRONG);
    }
    setLoading(false);
    setScheduledResource(null);
  };

  if (selectedWeb) {
    return (
      <WebPageTable
        web={selectedWeb}
        onBack={() => {
          setSelectedWeb(null);
        }}
        isAdminTable={true}
      />
    );
  }

  return (
    <>
      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">データ反映</Card.Title>
            <div
              className="d-flex flex-row justify-content-between align-items-end"
              style={{
                paddingTop: 8,
              }}
            >
              <div>
                <DropdownButton variant="Secondary" title={selectedStatus}>
                  {statusLabels.map((label) => (
                    <Dropdown.Item key={label} onClick={() => setSelectedStatus(label)}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <PaginationComponent currentPage={curPage} totalItems={resources.length} onPageChange={onPageChange} />
            </div>
          </Card.Header>
          <Card.Body className="table-responsive p-0 overflow-auto">
            <Table className="table-hover table-striped w-full">
              <thead>
                <tr>
                  <th>類別</th>
                  <th>テキスト</th>
                  <th>登録日時</th>
                  <th>反映予定日時</th>
                  <th>ステータス</th>
                  <th>アクション</th>
                </tr>
              </thead>
              <tbody>
                {resources.map((resource, i) => {
                  if (i < curPage * NUM_ITEMS_PER_PAGE || i >= (curPage + 1) * NUM_ITEMS_PER_PAGE) return null;
                  const status = resource.status;
                  const type = resource.type;
                  return (
                    <tr key={resource.id + resource.createdAt}>
                      <td>{RESOURCE_TYPE_LABELS[type]}</td>
                      <td className="common_table_name_cell">
                        {type === "WEBSITE" ? (
                          <a href={resource.name} target="_blank" rel="noopener noreferrer">
                            {resource.name}
                          </a>
                        ) : (
                          resource.name
                        )}
                      </td>
                      <td>{moment(resource.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                      <td>{getProcessTime(resource)}</td>
                      <td>
                        {getStatusLabel(resource)}
                        {resource.rejectReason && (
                          <button
                            className="button_reject_reason"
                            onClick={() => {
                              setProcessRejectedResource(resource);
                            }}
                          >
                            !
                          </button>
                        )}
                      </td>
                      <td>
                        <>
                          {type !== "FILE" && (
                            <Button
                              className="btn-outline mr-1"
                              variant="default"
                              onClick={() => {
                                setSelectedResource(resource);
                              }}
                            >
                              {BUTTON_LABELS.BUTTON_DETAIL}
                            </Button>
                          )}{" "}
                          {type === "WEBSITE" && (
                            <Button
                              className="btn-outline mr-1"
                              variant="default"
                              onClick={() => {
                                setSelectedWeb(resource);
                              }}
                            >
                              {BUTTON_LABELS.BUTTON_WEB_LIST}
                            </Button>
                          )}
                          {status === RESOURCE_STATUS.PROCESS_REQUESTING && (
                            <Button
                              className="btn-outline mr-1"
                              variant="default"
                              onClick={() => {
                                onProcessResource(resource);
                              }}
                            >
                              {BUTTON_LABELS.BUTTON_ACCEPT_PROCESS}
                            </Button>
                          )}
                          {(status === RESOURCE_STATUS.REPROCESS_REQUESTING ||
                            (status === RESOURCE_STATUS.FAILED && type === "FAQ")) && (
                            <Button
                              className="btn-outline mr-1"
                              variant="default"
                              onClick={() => {
                                onProcessResource(resource);
                              }}
                            >
                              {BUTTON_LABELS.BUTTON_ACCEPT_REPROCESS}
                            </Button>
                          )}
                          {status === RESOURCE_STATUS.FINISHED && type === "WEBSITE" && (
                            <Button
                              className="btn-outline mr-1"
                              variant="default"
                              onClick={() => {
                                onProcessResource(resource);
                              }}
                            >
                              再実行
                            </Button>
                          )}
                          {status === RESOURCE_STATUS.PROCESS_REQUESTING && type !== "FILE" && (
                            <Button
                              className="btn-outline mr-1"
                              variant="default"
                              onClick={() => {
                                setProcessRejectedResource(resource);
                              }}
                            >
                              {BUTTON_LABELS.BUTTON_REJECT_PROCESS}
                            </Button>
                          )}
                          {status === RESOURCE_STATUS.DELETE_REQUESTING && (
                            <Button
                              className="btn-outline mr-1"
                              variant="default"
                              onClick={() => {
                                setDeleteRejectedResource(resource);
                              }}
                            >
                              {BUTTON_LABELS.BUTTON_REJECT_DELETE}
                            </Button>
                          )}
                          {(status === RESOURCE_STATUS.PENDING ||
                            status === RESOURCE_STATUS.PROCESS_REJECTED ||
                            status === RESOURCE_STATUS.DELETE_REQUESTING ||
                            (type === "FAQ" && status === RESOURCE_STATUS.REPROCESS_REQUESTING) ||
                            (type === "FILE" && status === RESOURCE_STATUS.REPROCESS_REQUESTING) ||
                            (type === "FILE" && status === RESOURCE_STATUS.PROCESS_REQUESTING) ||
                            status === RESOURCE_STATUS.FINISHED ||
                            (type === "FAQ" && status === RESOURCE_STATUS.FAILED)) && (
                            <Button
                              onClick={() => handleDeleteResource(resource)}
                              className="btn-outline mr-1"
                              variant="default"
                            >
                              {BUTTON_LABELS.BUTTON_DELETE}
                            </Button>
                          )}
                          <Button
                            className="btn-outline mr-1"
                            variant="default"
                            onClick={() => {
                              setScheduledResource(resource);
                            }}
                          >
                            {BUTTON_LABELS.BUTTON_SET_SCHEDULED}
                          </Button>
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      {selectedResource && (
        <EditResourceDialog
          resource={selectedResource}
          onClose={() => {
            setSelectedResource(null);
          }}
          onUpdatedResource={onUpdatedResource}
        />
      )}
      <BatchModalDialog
        isOpen={!!scheduledResource}
        isCreateDialog={scheduledResource?.process === PROCESS_TYPES.CREATE}
        onClose={() => {
          setScheduledResource(null);
        }}
        onSubmit={onUpdateExecution}
        initExecutionType={scheduledResource?.execution}
        initExecutionDetail={scheduledResource?.executionDetail}
        editable={scheduledResource?.status !== RESOURCE_STATUS.PROCESSING}
        enableRecurring={scheduledResource?.type === RESOURCE_TYPES.WEBSITE}
      />
      <RejectResourceDialog
        isOpen={processRejectedResource || deleteRejectedResource}
        reason={(processRejectedResource || deleteRejectedResource)?.rejectReason || ""}
        onClose={() => {
          processRejectedResource ? setProcessRejectedResource(null) : setDeleteRejectedResource(null);
        }}
        onReject={(reason) => {
          onRejectProcessResource(processRejectedResource || deleteRejectedResource, reason);
        }}
      />
      {loading && <FanLoadingIcon size={80} />}
    </>
  );
};
ResourcePage.propTypes = propTypes;
ResourcePage.defaultProps = defaultProps;
export default ResourcePage;
