import FanLoadingIcon from "components/FanLoadingIcon";
import { useDialog } from "contexts/DialogContext";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  adminDeleteConversation,
  listExternalConversationsSortByDate,
  listExternalConversationsSortByEval,
  listInternalConversationsSortByDate,
  listInternalConversationsSortByEval,
  listMessagesByMonth,
} from "../../apis/MessageApi";
import useCsvDownloader from "../../components/Hooks/useCsvDownloader";
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import { MESSAGES } from "../../lang/ja";
import QaTable from "./QaTable";

const QaPage = () => {
  const { userInfo } = useSelector((state) => state.users);
  // if senkyo admin we can browse other governmentId
  const governmentId =
    APP_TYPE === APP_TYPES.ADMIN ? useParams()?.governmentId || userInfo?.governmentId : userInfo?.governmentId;
  const [qas, setQas] = React.useState([]);
  const nextTokenRef = React.useRef(null);
  const [sortByDate, setSortByDate] = React.useState(true);
  const [dateAscend, setDateAscend] = React.useState(false);
  const [evalAscend, setEvalAscend] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const { downloadCsv } = useCsvDownloader();
  const { error } = useDialog();
  /***** Processing *****/
  const downloadConversationList = async (governmentId) => {
    const res = await (APP_TYPE === APP_TYPES.INSIDE_ADMIN || APP_TYPE === APP_TYPES.ADMIN
      ? listInternalConversationsSortByDate(governmentId, "DESC", null)
      : listExternalConversationsSortByDate(governmentId, "DESC", null));
    if (res.success) {
      setQas(res.data);
      nextTokenRef.current = res.nextToken;
    }
  };

  React.useEffect(() => {
    if (governmentId) downloadConversationList(governmentId);
  }, [governmentId]);
  /***** Sub Components *****/
  /***** Event Handlers *****/
  const onDownloadQas = async () => {
    const nextToken = nextTokenRef.current;
    if (nextToken) {
      const res = await (APP_TYPE === APP_TYPES.INSIDE_ADMIN
        ? sortByDate
          ? listInternalConversationsSortByDate(governmentId, dateAscend ? "ASC" : "DESC", nextToken)
          : listInternalConversationsSortByEval(governmentId, evalAscend ? "ASC" : "DESC", nextToken)
        : sortByDate
        ? listExternalConversationsSortByDate(governmentId, dateAscend ? "ASC" : "DESC", nextToken)
        : listExternalConversationsSortByEval(governmentId, evalAscend ? "ASC" : "DESC", nextToken));
      if (res.success) {
        setQas((prevQas) => [...prevQas, ...res.data]);
        nextTokenRef.current = res.nextToken;
      }
    }
  };
  const onDeleteQas = async (conversation) => {
    const res = await adminDeleteConversation(
      conversation.ownerId,
      conversation.createdAt,
      APP_TYPE === APP_TYPES.INSIDE_ADMIN || APP_TYPE === APP_TYPES.ADMIN
    );
    if (res.success) {
      nextTokenRef.current = res.nextToken;
      downloadConversationList(governmentId);
    }
    return Boolean(res.success);
  };

  const onSortModeChange = async (newSortByDate, newDateAscend, newEvalAscend) => {
    if (newSortByDate === sortByDate && newDateAscend === dateAscend && newEvalAscend === evalAscend) return;
    setSortByDate(newSortByDate);
    setDateAscend(newDateAscend);
    setEvalAscend(newEvalAscend);
    const nextToken = nextTokenRef.current;
    if (nextToken) {
      const res = await (APP_TYPE === APP_TYPES.INSIDE_ADMIN || APP_TYPE === APP_TYPES.ADMIN
        ? newSortByDate
          ? listInternalConversationsSortByDate(governmentId, newDateAscend ? "ASC" : "DESC", null)
          : listInternalConversationsSortByEval(governmentId, newEvalAscend ? "ASC" : "DESC", null)
        : newSortByDate
        ? listExternalConversationsSortByDate(governmentId, newDateAscend ? "ASC" : "DESC", null)
        : listExternalConversationsSortByEval(governmentId, newEvalAscend ? "ASC" : "DESC", null));
      if (res.success) {
        setQas(res.data);
      }
    } else {
      if (newSortByDate) {
        const order = newDateAscend ? 1 : -1;
        qas.sort((q1, q2) => {
          return order * q1.createdAt.localeCompare(q2.createdAt);
        });
      } else {
        const order = newEvalAscend ? 1 : -1;
        qas.sort((q1, q2) => {
          return order * JSON.stringify(q1.evaluation).localeCompare(JSON.stringify(q2.evaluation));
        });
      }
      setQas(Object.assign([], qas));
    }
  };
  const onDownloadCsv = async (filterValues) => {
    const { month, rating } = filterValues;
    if (!month) return;
    if (loading) return;
    setLoading(true);
    const res = await listMessagesByMonth(governmentId, filterValues.month, APP_TYPE === APP_TYPES.OUTSIDE_ADMIN);
    setLoading(false);
    if (res.success) {
      let data = [];
      for (const conv of res.data) {
        const conversationName = conv.name;
        if (rating && !conv.evaluation.includes(rating)) continue;
        let row;
        for (const message of conv.messages) {
          if (message.role === "USER") {
            row = [conversationName, "", moment(message.createdAt).format("yyyy/MM/DD HH:mm"), message.text];
          } else {
            const evaluation = message.like > 0 ? "良い" : message.like < 0 ? "悪い" : "評価なし";
            row[1] = evaluation;
            row[4] = message.text + (message.url ? "\n" + message.url : "");
            data.push(row);
          }
        }
      }
      data = [["最初の質問", "評価", "日時", "質問", "回答"], ...data];
      await downloadCsv(data, "qa_" + month + ".csv");
    } else {
      error(MESSAGES.SOMETHING_WRONG);
    }
  };
  /***** Main Render *****/
  return (
    <>
      <QaTable
        qas={qas}
        onDownloadQas={onDownloadQas}
        onDeleteQas={onDeleteQas}
        nextPageIndex={nextTokenRef.current}
        onDownloadCsv={onDownloadCsv}
        sortByDate={sortByDate}
        dateAscend={dateAscend}
        evalAscend={evalAscend}
        onSortModeChange={onSortModeChange}
      />
      {loading && <FanLoadingIcon size={80} />}
    </>
  );
};

export default QaPage;
