import { faArrowDown91, faArrowUp19 } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Button, Card, Col, Table } from "react-bootstrap";
import PaginationComponent from "../../components/PaginationComponent";
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import { NUM_ITEMS_PER_PAGE } from "../../constants/table";
import { BUTTON_LABELS } from "../../lang/ja";
import CSVDownloadDialog from "./CSVDownloadDialog";
import DeleteDialog from "./DeleteDialog";
import MessageList from "./MessageList";

const propTypes = {
  qas: PropTypes.array,
  nextPageIndex: PropTypes.string,
  onDownloadQas: PropTypes.func,
  onDeleteQas: PropTypes.func,
  onDownloadCsv: PropTypes.func,
  sortByDate: PropTypes.bool,
  dateAscend: PropTypes.bool,
  evalAscend: PropTypes.bool,
  onSortModeChange: PropTypes.func,
};
const defaultProps = {
  qas: [],
  nextPageIndex: null,
  onDownloadQas: () => {},
  onDeleteQas: () => {},
  onDownloadCsv: () => {},
  sortByDate: true,
  dateAscend: false,
  evalAscend: true,
  onSortModeChange: () => {},
};

const QaTable = (props) => {
  const {
    qas,
    nextPageIndex,
    onDownloadQas,
    onDeleteQas,
    sortByDate,
    dateAscend,
    evalAscend,
    onSortModeChange,
    onDownloadCsv,
  } = props;
  const [curPage, setCurPage] = React.useState(0);
  const numPages = Math.ceil(qas.length / NUM_ITEMS_PER_PAGE);
  const [selectedQa, setSelectedQa] = React.useState(null);
  const [qaResource, setDeleteDiagOpen] = React.useState(null);
  const [csvDownloadDialogOpen, setCsvDownloadDialogOpen] = React.useState(false);

  const onPageChange = (page) => {
    if (page + 6 > numPages) {
      onDownloadQas();
    }
    setCurPage(page);
  };

  const onDeleteQaAction = async () => {
    await onDeleteQas(qaResource);
    // TODO UX: potentially show checkmark icon upon success?
    setDeleteDiagOpen(null);
  };

  if (selectedQa) {
    return (
      <MessageList
        onClose={() => {
          setSelectedQa(null);
        }}
        conversation={selectedQa}
      />
    );
  }
  return (
    <>
      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">QAリスト</Card.Title>
            <div className="d-flex flex-row justify-content-between align-items-end" style={{ paddingTop: 4 }}>
              <PaginationComponent
                currentPage={curPage}
                nextPageIndex={nextPageIndex}
                totalItems={qas.length}
                onPageChange={onPageChange}
              />
              <Button
                className="btn-outline mr-1"
                variant="primary"
                onClick={() => {
                  setCsvDownloadDialogOpen(true);
                }}
              >
                {BUTTON_LABELS.BUTTON_DOWNLOAD}
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="table-responsive p-0 overflow-auto">
            <Table className="table-hover table-striped w-full">
              <thead>
                <tr>
                  <th>最初の質問文</th>
                  <th
                    style={{
                      cursor: "pointer",
                      color: !sortByDate ? "#23CCEF" : undefined,
                    }}
                    onClick={() => {
                      onSortModeChange(false, dateAscend, !sortByDate ? !evalAscend : evalAscend);
                    }}
                  >
                    評価
                    <FontAwesomeIcon icon={evalAscend ? faArrowUp19 : faArrowDown91} />
                  </th>
                  {APP_TYPE === APP_TYPES.INSIDE_ADMIN && <th>ユーザー</th>}
                  <th
                    style={{
                      cursor: "pointer",
                      color: sortByDate ? "#23CCEF" : undefined,
                    }}
                    onClick={() => {
                      onSortModeChange(true, sortByDate ? !dateAscend : dateAscend, evalAscend);
                    }}
                  >
                    開始日
                    <FontAwesomeIcon icon={dateAscend ? faArrowUp19 : faArrowDown91} />
                  </th>
                  <th>アクション</th>
                </tr>
              </thead>
              <tbody>
                {qas.map((qa, i) => {
                  if (i < curPage * NUM_ITEMS_PER_PAGE || i >= (curPage + 1) * NUM_ITEMS_PER_PAGE) return null;
                  return (
                    <tr key={qa.createdAt}>
                      <td>{qa.name}</td>
                      <td>
                        {qa.evaluation && qa.evaluation[0] ? (
                          <Badge
                            variant={qa.evaluation[0] === "良い" ? "primary" : "danger"}
                            style={{
                              padding: "0.4em 0.5em",
                              fontSize: "0.8rem",
                            }}
                          >
                            {qa.evaluation[0]}
                          </Badge>
                        ) : (
                          <Badge
                            variant="secondary"
                            style={{
                              padding: "0.4em 0.5em",
                              fontSize: "0.8rem",
                            }}
                          >
                            評価なし
                          </Badge>
                        )}
                      </td>
                      {APP_TYPE === APP_TYPES.INSIDE_ADMIN && <td>{qa?.owner && qa.owner.username}</td>}
                      <td>{moment(qa.createdAt).format("YYYY/MM/DD HH:mm")}</td>
                      <td>
                        <>
                          <Button
                            className="btn-outline mr-1"
                            variant="default"
                            onClick={() => {
                              setSelectedQa(qa);
                            }}
                          >
                            詳細
                          </Button>
                          {APP_TYPE === APP_TYPES.ADMIN && (
                            <Button
                              className="btn-outline mr-1"
                              variant="default"
                              onClick={() => {
                                setDeleteDiagOpen(qa);
                              }}
                            >
                              削除
                            </Button>
                          )}
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>

      {qaResource && (
        <DeleteDialog
          resource={qaResource}
          title={"QA一括削除"}
          message={"このQAを全て削除します。よろしいでしょうか？"}
          onClose={() => {
            setDeleteDiagOpen(null);
          }}
          onConfirm={() => {
            onDeleteQaAction(qaResource);
          }}
        />
      )}
      <CSVDownloadDialog
        isOpen={csvDownloadDialogOpen}
        onClose={() => {
          setCsvDownloadDialogOpen(false);
        }}
        onDownload={(filterValues) => {
          onDownloadCsv(filterValues);
          setCsvDownloadDialogOpen(false);
        }}
      />
    </>
  );
};
QaTable.propTypes = propTypes;
QaTable.defaultProps = defaultProps;
export default QaTable;
