import { API } from 'aws-amplify';
import {
  listInternalMessages,
  findExternalConversationsByCityId,
  findExternalConversationsSortByEvaluation,
  listExternalMessages,
  getExternalMessage as getExternalMessageCmd,
  getInternalMessage as getInternalMessageCmd,
  findInternalConversationsByCityId
 } from '../graphql/queries';
import {
  updateInternalMessage,
  updateExternalMessage,
  deleteInternalConversation,
  deleteExternalConversation,
  deleteInternalMessage,
  deleteExternalMessage
} from '../graphql/mutations';
import {
  createFaq,
  updateResource
} from './ResourceApi';
import { APP_AUTH_MODE } from '../constants/app';
import {
  customFindInternalConversationsByCityId,
  customFindInternalConversationsByEvaluation
} from "../graphql/customQueries";

const _getMessage = async (conversationId, msgCreatedAt, isExternal=true) => {
  try {
    const query = isExternal? getExternalMessageCmd: getInternalMessageCmd;
    const res = await API.graphql({
      query,
      variables: {
        conversationId,
        createdAt: msgCreatedAt
      },
      authMode: APP_AUTH_MODE
    })
    return {
      success: true,
      data: res.data[isExternal? 'getExternalMessage': 'getInternalMessage']
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const getExternalMessage = async (conversationId, msgCreatedAt) => {
  return await _getMessage(conversationId, msgCreatedAt, true);
}

const getInternalMessage = async (conversationId, msgCreatedAt) => {
  return await _getMessage(conversationId, msgCreatedAt, false);
}

const _listConversationsSortByDate = async (cityId, nextToken, sortDirection='DESC', isExternal=true) => {
  try {
    const res = await API.graphql({
      query: isExternal? findExternalConversationsByCityId: customFindInternalConversationsByCityId,
      variables: {
        cityId: cityId,
        sortDirection,
        nextToken
      },
      authMode: APP_AUTH_MODE
    })
    
    if (res.data[isExternal? 'findExternalConversationsByCityId': 'findInternalConversationsByCityId']) {
      console.log("VANVIET", res)
      const data = res.data[isExternal? 'findExternalConversationsByCityId': 'findInternalConversationsByCityId'];
      const items = data.items;
      for(const item of items) {
        if (item.evaluation) {
          item.evaluation = JSON.parse(item.evaluation);
        }
      }
      return {
        success: true,
        data: items,
        nextToken: data.nextToken
      }
    }
    return {
      success: false,
      error: res.data.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const _listConversationsSortByEval = async (cityId, nextToken, sortDirection='DESC', isExternal=true) => {
  try {
    const res = await API.graphql({
      query: isExternal? findExternalConversationsSortByEvaluation: customFindInternalConversationsByEvaluation,
      variables: {
        cityId: cityId,
        sortDirection,
        nextToken
      },
      authMode: APP_AUTH_MODE
    })
    if (res.data[isExternal? 'findExternalConversationsSortByEvaluation': 'findInternalConversationsByEvaluation']) {
      const data = res.data[isExternal? 'findExternalConversationsSortByEvaluation': 'findInternalConversationsByEvaluation'];
      const items = data.items;
      for(const item of items) {
        if (item.evaluation) {
          item.evaluation = JSON.parse(item.evaluation);
        }
      }
      return {
        success: true,
        data: items,
        nextToken: data.nextToken
      }
    }
    return {
      success: false,
      error: res.data.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listExternalConversationsSortByDate = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortByDate(cityId, nextToken, sortDirection, true);
}

const listInternalConversationsSortByDate = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortByDate(cityId, nextToken, sortDirection, false)
}

const listExternalConversationsSortByEval = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortByEval(cityId, nextToken, sortDirection, true);
}

const listInternalConversationsSortByEval = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortByEval(cityId, nextToken, sortDirection, false)
}

const _listConversationMessages = async (conversationId, nextToken, isExternal=true) => {
  try {
    const res = await API.graphql({
      query: isExternal? listExternalMessages : listInternalMessages,
      variables: {
        conversationId: conversationId,
        sortDirection: 'DESC',
        nextToken
      },
      authMode: APP_AUTH_MODE
    })
    const data = res.data[isExternal? 'listExternalMessages': 'listInternalMessages'];
    if (data) {
      data.items.reverse();
      return {
        success: true,
        data: data.items,
        nextToken: data.nextToken
      }
    }
    return {
      success: false,
      error: res.data.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listExternalConversationMessages = async (conversationId, nextToken=null) => {
  return _listConversationMessages(conversationId, nextToken, true);
}

const listInternalConversationMessages = async (conversationId, nextToken=null) => {
  return _listConversationMessages(conversationId, nextToken, false);
}

const adminDeleteConversation = async(ownerId, createdAt, isInternal) => {
  try {
    const mutation = isInternal ? deleteInternalConversation : deleteExternalConversation;
    const res = await API.graphql({
      query: mutation,
      variables: {
        input: {
          ownerId: ownerId,
          createdAt: createdAt
        }
      },
      authMode: APP_AUTH_MODE
    });
  
    const mutationResult = res.data[isInternal ? 'deleteInternalConversation' : 'deleteExternalConversation'];
    const success = Boolean(mutationResult);

    return {
      success,
      data: success ? res : null,
      nextToken: success ? mutationResult.nextToken : null,
      error: success ? null : res.errors || 'Deletion unsuccessful'
    };
  } catch (err) {
    return {
      success: false,
      error: err
    };
  }
}

const adminDeleteConversationMessage = async (conversationId, createdAt, isInternal) => {
  try {
    const mutation = isInternal ? deleteInternalMessage : deleteExternalMessage;
    const res = await API.graphql({
      query: mutation,
      variables: {
        input: {
          conversationId: conversationId,
          createdAt: createdAt
        }
      },
      authMode: APP_AUTH_MODE
    });

    const mutationResult = res.data[isInternal ? 'deleteInternalMessage' : 'deleteExternalMessage'];
    const success = Boolean(mutationResult);

    return {
      success,
      data: success ? res : null,
      nextToken: success ? mutationResult.nextToken : null,
      error: success ? null : res.errors || 'Deletion unsuccessful'
    };
  } catch (err) {
    return {
      sucess: false,
      error: err
    }
  }
}

const fixChatbotAnswer =  async (governmentId, conversationId, createdAt, faq, question, answer, url, isExternal=true) => {
  console.log('HERE1')
  try {
    const srcMsgKey = JSON.stringify({
      conversationId,
      createdAt
    })
    let faqRes = null;
    console.log('HERE2', faq)
    if (faq) {
      faq.name = question;
      faq.answer = answer;
      faq.url = url;
      faqRes = await updateResource(faq, {name: question, answer, url});
    }
    else {
      faqRes = await createFaq(governmentId, question, answer, url, srcMsgKey, isExternal);
    }
    console.log('HERE3', faqRes)
    if (faqRes?.error) {
      return faqRes;
    }
    const newFaq = faqRes.data;
    let msg;
    if (!newFaq?.createdAt) {
      const msgRes = await API.graphql({
        query: isExternal? updateExternalMessage: updateInternalMessage,
        variables: {
          input: {
            conversationId,
            createdAt: createdAt,
            fixAnswerCreatedAt: newFaq.createdAt
          }
        },
        authMode: APP_AUTH_MODE
      });
      if (msgRes.errors)  {
        return {
          success: false,
          error: msgRes.errors
        }
      }
      msg = msgRes.data[isExternal? 'updateExternalMessage': 'updateInternalMessage'];
    }
    return {
      success: true,
      data: msg
    }
  }
  catch (e) {
    console.log("HERE4", e)
    return {
      success: false,
      error: e
    }
  }
}

const listMessagesByMonth = async (governmentId, month, isExternal) => {
  try {
    const query = isExternal? findExternalConversationsByCityId: findInternalConversationsByCityId;
    const property = isExternal? 'findExternalConversationsByCityId': 'findInternalConversationsByCityId';
    let nextToken = null;
    let params = {
      cityId: governmentId,
      createdAt: {
        beginsWith: month
      }
    }
    const conversations = [];
    // eslint-disable-next-line no-constant-condition
    while(true) {
      params.nextToken = nextToken;
      const conversationRes = await API.graphql({
        query,
        variables: params,
        authMode: APP_AUTH_MODE
      });
      conversations.push(...conversationRes.data[property].items);
      nextToken = conversationRes.data[property].nextToken;
      if (!nextToken) break;
    }
    for(const conversation of conversations) {
      conversation.messages = [];
      nextToken = null;
      const msgQuery = isExternal? listExternalMessages: listInternalMessages;
      const msgProperty = isExternal? 'listExternalMessages': 'listInternalMessages';
      const msgParams = {
        conversationId: conversation.id,
        sortDirection: 'ASC'
      }
      // eslint-disable-next-line no-constant-condition
      while (true) {
        msgParams.nextToken = nextToken;
        const msgRes = await API.graphql({
          query: msgQuery,
          variables: msgParams,
          authMode: APP_AUTH_MODE
        });
        conversation.messages.push(...msgRes.data[msgProperty].items);
        nextToken = msgRes.data[msgProperty].nextToken;
        if (!nextToken) break;
      }
    }
    return {
      success: true,
      data: conversations
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

export {
  getExternalMessage,
  getInternalMessage,
  listExternalConversationsSortByDate,
  listInternalConversationsSortByDate,
  listExternalConversationsSortByEval,
  listInternalConversationsSortByEval,
  listExternalConversationMessages,
  listInternalConversationMessages,
  adminDeleteConversation,
  adminDeleteConversationMessage,
  fixChatbotAnswer,
  listMessagesByMonth
}
