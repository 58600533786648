import React from 'react';
import PropTypes from 'prop-types';
const propTypes={
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string
};
const defaultProps={
  size: 20,
  color: 'currentColor'
};
const FontIcon = (props) => {
  /*** States and Variables ***/
  const {size, color} = props;
  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/
  return <svg width={size} height={size} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 3C2 3.5625 1.53125 4 1 4C0.4375 4 0 3.5625 0 3V1.5C0 0.6875 0.65625 0 1.5 0H6H10.5C11.3125 0 12 0.6875 12 1.5V3C12 3.5625 11.5312 4 11 4C10.4375 4 10 3.5625 10 3V2H7V12H8C8.53125 12 9 12.4688 9 13C9 13.5625 8.53125 14 8 14H4C3.4375 14 3 13.5625 3 13C3 12.4688 3.4375 12 4 12H5V2H2V3ZM12 8.5C12 9.0625 11.5312 9.5 11 9.5C10.4375 9.5 10 9.0625 10 8.5V7.5C10 6.6875 10.6562 6 11.5 6H18.5C19.3125 6 20 6.6875 20 7.5V8.5C20 9.0625 19.5312 9.5 19 9.5C18.4375 9.5 18 9.0625 18 8.5V8H16V12H16.5C17.0312 12 17.5 12.4688 17.5 13C17.5 13.5625 17.0312 14 16.5 14H13.5C12.9375 14 12.5 13.5625 12.5 13C12.5 12.4688 12.9375 12 13.5 12H14V8H12V8.5Z" fill={color}/>
  </svg>;
}
FontIcon.propTypes = propTypes;
FontIcon.defaultProps = defaultProps;
export default FontIcon;